export interface IFileFilter {
  tag: string[];
  archived: boolean;
  uploadedBy: string;
  fromDate: Date;
  untilDate: Date;
  groupid: string;
  searchByName: string;
  sortby: string;
  sortOrder: string;
  type: string;
  filterRole: string;
  roleMailAddress: string;
  rangeFilter: string[];
}

export class FileFilter implements IFileFilter {
  public tag: string[];
  public archived: boolean;
  public uploadedBy: string;
  public fromDate: Date;
  public untilDate: Date;
  public groupid: string;
  public searchByName: string;
  public sortby: string;
  public sortOrder: string;
  public type: string;
  public filterRole: string;
  public roleMailAddress: string;
  public rangeFilter: string[];
  constructor(data: any = {}) {
    this.tag = data.tag || [];
    this.archived = data.archived || false;
    this.groupid = data.groupid || '';
    this.searchByName = data.searchByName || '';
    this.sortby = data.sortby || 'lastModifiedDate';
    this.sortOrder = data.sortOrder || 'DESC';
    this.uploadedBy = data.uploadedBy || '';
    this.type = data.type || '';
    this.fromDate = data.fromDate || null;
    this.untilDate = data.untilDate || null;
    this.rangeFilter = data.rangeFilter || [];
    this.filterRole = data.filterRole || '';
    this.roleMailAddress = data.roleMailAddress || '';
  }
}

export enum sortOrder {
  ASC = 0,
  DESC,
}

export interface ITicketFilter {
  tag: string[];
  archived: boolean;
  aggs_type: string;
  aggs_value: string;
  view: string;
  sortOrder: string;
  sortby: string;
  filterRole: string;
  roleMailAddress: string;
  status: string[];
  rangeFilter: string[];
  projects: string[];
  activeDatabase: string;
  groupId: string;
  auditId: string;
  mapId: string;
  searchById: string;
  templateGroupId: string;
  templateId: string;
  fromDate: Date;
  untilDate: Date;
}
export class TicketFilter implements ITicketFilter {
  public tag: string[];
  public archived: boolean;
  public aggs_type: string;
  public aggs_value: string;
  public view: string;
  public sortOrder: string;
  public sortby: string;
  public filterRole: string;
  public roleMailAddress: string;
  public status: string[];
  public rangeFilter: string[];
  public projects: string[];
  public activeDatabase: string;
  public groupId: string;
  public auditId: string;
  public mapId: string;
  public searchById: string;
  public fromDate: Date;
  public untilDate: Date;
  public templateGroupId: string;
  public templateId: string;
  constructor(data: any = {}) {
    this.tag = data.tag || [];
    this.archived = data.archived || false;
    this.aggs_type = data.aggs_type || '';
    this.aggs_value = data.aggs_value || '';
    this.view = data.view || 'list';
    this.sortby = data.sortby || 'LASTMODIFIEDDATE';
    this.sortOrder = data.sortOrder || 'DESC';
    this.filterRole = data.filterRole || '';
    this.roleMailAddress = data.roleMailAddress || '';
    this.status = data.status || [];
    this.rangeFilter = data.rangeFilter || [];
    this.projects = data.projects || [];
    this.activeDatabase = data.activeDatabase || '';
    this.groupId = data.groupId || '';
    this.auditId = data.auditId || '';
    this.templateGroupId = data.templateGroupId || '';
    this.templateId = data.templateId || '';
    this.mapId = data.mapId || '';
    this.searchById = data.searchById || '';
    this.fromDate = data.fromDate || null;
    this.untilDate = data.untilDate || null;
  }
}

export interface IAuditFilter {
  archived: boolean;
  aggs_type: string;
  aggs_value: string;
  view: string;
  sortOrder: string;
  sortby: string;
  groupid: string;
  template: string;
  searchById: string;
  status: string[];
  rangeFilter: string[];
  projects: string[];
  filterRole: string;
  roleMailAddress: string;
  fromDate: Date;
  untilDate: Date;
  tag: string[];
}

export class AuditFilter implements IAuditFilter {
  public archived: boolean;
  public aggs_type: string;
  public aggs_value: string;
  public view: string;
  public sortOrder: string;
  public sortby: string;
  public groupid: string;
  public template: string;
  public searchById: string;
  public status: string[];
  public rangeFilter: string[];
  public projects: string[];
  public filterRole: string;
  public roleMailAddress: string;
  public fromDate: Date;
  public untilDate: Date;
  public tag: string[];
  constructor(data: any = {}) {
    this.archived = data.archived || false;
    this.aggs_type = data.aggs_type || '';
    this.aggs_value = data.aggs_value || '';
    this.view = data.view || 'list';
    this.sortby = data.sortby || 'lastmodifieddate';
    this.sortOrder = data.sortOrder || 'desc';
    this.groupid = data.groupid || '';
    this.searchById = data.searchById || '';
    this.status = data.status || [];
    this.rangeFilter = data.rangeFilter || [];
    this.projects = data.projects || [];
    this.template = data.template || '';
    this.filterRole = data.filterRole || '';
    this.roleMailAddress = data.roleMailAddress || '';
    this.fromDate = data.fromDate || null;
    this.untilDate = data.untilDate || null;
    this.tag = data.tag || [];
  }
}

export interface ITemplateFilter {
  archived: boolean;
  aggs_type: string;
  aggs_value: string;
  view: string;
  groupid: string;
  searchByName: string;
  dummy: number;
  rangeFilter: string[];
  fromDate: Date;
  untilDate: Date;
  filterRole: string;
  roleMailAddress: string;
  sortOrder?: string;
  sortby?: string;
  tag: string[];
}

export class TemplateFilter implements ITemplateFilter {
  public archived: boolean;
  public aggs_type: string;
  public aggs_value: string;
  public view: string;
  public groupid: string;
  public searchByName: string;
  public dummy: number;
  public rangeFilter: string[];
  public fromDate: Date;
  public untilDate: Date;
  public filterRole: string;
  public roleMailAddress: string;
  public tag: string[];

  constructor(data: any = {}) {
    this.archived = data.archived || false;
    this.aggs_type = data.aggs_type || '';
    this.aggs_value = data.aggs_value || '';
    this.view = data.view || '';
    this.groupid = data.groupid || '';
    this.searchByName = data.searchByName || '';
    this.dummy = data.dummy || null;
    this.rangeFilter = data.rangeFilter || [];
    this.fromDate = data.fromDate || null;
    this.untilDate = data.untilDate || null;
    this.filterRole = data.filterRole || '';
    this.roleMailAddress = data.roleMailAddress || '';
    this.tag = data.tag || [];
  }
}

export interface IProjectFilter {
  view: string;
  filter: string[];
  searchByName: string;
  contract: string;
}
export class ProjectFilter implements IProjectFilter {
  public view: string;
  public filter: string[];
  public searchByName: string;
  public contract: string;
  constructor(data: any = {}) {
    this.view = data.view || 'grid';
    this.filter = data.filter || ['all'];
    this.searchByName = data.searchByName || '';
    this.contract = data.contract || '';
  }
}
export interface IReporterFilter {
  aggs_type: string;
  aggs_value: string;
  view: string;
  sortOrder: string;
  sortby: string;
  projects: string[];
  activeDatabase: string;
  groupId: string;
  mapId: string;
  searchById: string;
}
export class ReporterFilter implements IReporterFilter {
  aggs_type: string;
  aggs_value: string;
  view: string;
  sortOrder: string;
  sortby: string;
  projects: string[];
  activeDatabase: string;
  groupId: string;
  mapId: string;
  searchById: string;
  constructor(data: any = {}) {
    this.aggs_type = data.aggs_type || 'project';
    this.aggs_value = data.aggs_value || '';
    this.view = data.view || 'list';
    this.sortOrder = data.sortOrder || '';
    this.sortby = data.sortby || '';
    this.projects = data.projects || '';
    this.activeDatabase = data.activeDatabase || '';
    this.groupId = data.groupId || '';
    this.mapId = data.mapId || '';
    this.searchById = data.searchById || '';
  }
}
