/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { ConfirmationDialog, ConfirmationOptions } from './CustomDialog';

const CustomDialogServiceContext = React.createContext<(options: ConfirmationOptions) => Promise<void>>(Promise.reject);

export const useCustomDialog = () => React.useContext(CustomDialogServiceContext);

export const CustomDialogServiceProvider = ({ children }) => {
  const [confirmationState, setConfirmationState] = React.useState<ConfirmationOptions | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const awaitingPromiseRef = React.useRef<{ resolve: () => void; reject: () => void }>();

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options);
    setOpen(true);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = (_evt, reason: string = '') => {
    if (reason === 'backdropClick') {
      if (!confirmationState.backDropEnabled) {
        return false;
      }
    }
    if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    setOpen(false);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
    setOpen(false);
  };

  const handleClosed = () => {
    setConfirmationState(null);
  };

  React.useEffect(() => {
    window.addEventListener('popstate', e => handleClose(e));
    return () => {
      window.removeEventListener('popstate', e => handleClose(e));
    };
  }, []);

  return (
    <>
      <CustomDialogServiceContext.Provider value={openConfirmation} children={children} />
      <ConfirmationDialog open={open} onSubmit={handleSubmit} onClose={handleClose} onClosed={handleClosed} children={children} {...confirmationState}></ConfirmationDialog>
    </>
  );
};
