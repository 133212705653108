import React from 'react';

import { socket } from './socket';
import { AppStateContext } from '..';
import * as langEn from '../language/lang.json';
import { ProjectModel } from '../models/project.model';
import { appState, IAppState } from '../utils/appState';
import { initializeLanguage } from '../utils/languageController';

export interface IAppStateContext {
  // ws: any;
  socket: any;
  language: any;
  appState: IAppState;
  setLang: (lang: any) => void;
}
export interface IEdState {
  maps: any;
  templates: any;
  module: string;
  personList?: any;
  isEditing?: boolean;
  personListRoles?: any;
  project?: ProjectModel;
  isLeftMenuCollapsed: boolean;
  resetQuery: (key: string) => void;
  stateUpdater?: (action: any) => void;
  setLang: (translations: any) => void;
}

export const AppStateControls = ({ children }) => {
  const [language, setLanguage] = React.useState(langEn);

  /**
   * Language Controls
   */
  const setLang = translations => {
    setLanguage(translations);
  };

  React.useEffect(() => {
    let lang = JSON.parse(sessionStorage.getItem('edappstate') || '{}');
    initializeLanguage(lang?.user?.user_lang || undefined).then(langs => {
      setLang(langs);
    });
  }, []);

  return (
    <AppStateContext.Provider
      value={{
        socket,
        // ws,
        appState,
        language,
        setLang,
      }}>
      {children}
    </AppStateContext.Provider>
  );
};
