import React from 'react';
import Draggable from 'react-draggable';
import { Dialog, Paper } from '@material-ui/core';

export interface ConfirmationOptions {
  catchOnCancel?: boolean;
  children: React.ReactElement;
  customClass?: string;
  maxWidthProp?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  backDropEnabled?: boolean;
  disableEnforceFocus?: boolean;
}
interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: (reason: string) => void;
  onClosed: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, onClose, onClosed, children, customClass, maxWidthProp, onSubmit, disableEnforceFocus }) => {
  function PaperComponent(props) {
    const calculateBounds = () => {
      const height = (window.innerHeight - 610) / 2;
      const width = (window.innerWidth - 1170) / 2;
      return { top: -height, left: -width, right: width, bottom: height + 580 };
    };
    return (
      //@ts-ignore
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
        bounds={calculateBounds()}

        // bounds="body"
      >
        <Paper style={{ margin: 0, marginBottom: 0 }} {...props} tabIndex={-1} />
      </Draggable>
    );
  }
  return (
    <Dialog
      open={open}
      disableEnforceFocus={disableEnforceFocus || false}
      // TransitionComponent={Transition}
      onExited={onClosed}
      onClose={onClose}
      maxWidth={maxWidthProp ? maxWidthProp : 'xl'}
      aria-labelledby="popup-dialog"
      aria-describedby="popup-dialog-description"
      className={'custom-dialog ' + (customClass ? customClass : '')}
      PaperComponent={PaperComponent}
      PaperProps={{ tabIndex: -1 }}>
      {open &&
        React.Children.map(children, (child: React.ReactElement) => {
          return React.cloneElement(child, {
            onClose: onClose,
            onSubmit: onSubmit,
          });
        })}
    </Dialog>
  );
};
