import { changeSortKeyForPrint, getTimelinePermission } from '../pages/printDialog/PrintService';
import { generateChannelId } from '../utils/utils';
import { getDate } from '../utils/dateHelper';
import { Author, IAuthor } from './author';
import { Dates, IDates } from './dates';

import pictureIcon from './../images/picture.png';
import startDateIcon from './../images/start-date.png';
import dueDateIcon from './../images/due-date.svg';
import informedIcon from './../images/informed.svg';
import ticketsIcon from './../images/tickets.svg';
import attachmentIcon from './../images/attachements.svg';
import locationMapIcon from './../images/location-map.png';
import timelineIcon from './../images/timeline.png';
import responsibleIcon from './../images/RESPONSIBLE.svg';
import cAndIIcon from './../images/c_and_i.png';

export interface ITicketLayout {
  _id: string;
  name: string;
  options: IOptionsTicket;
  default: boolean;
  archived: null | string;
  iconUrl: string;
}

export interface IOptionsTicket {
  coverSheet: IAttachment;
  drawings: IAttachment;
  description: IAttachment;
  picture: IPicture;
  locationMap: IAttachment;
  startDate: IAttachment;
  dueDate: IAttachment;
  timeline: IAttachment;
  responsible: IAttachment;
  consultedInformed: IAttachment;
  attachments: IAttachment;
  preventPageBreak: IAttachment;
  ticketPageBreak: IAttachment;
}

export interface IPicture {
  checked: boolean;
  pictureType: IPictureSizeClass;
  pictureSize: IPictureSizeClass;
}
export interface ITimeline {
  checked: boolean;
  timeLineType: IPictureSizeClass;
}

export interface IPictureSizeClass {
  value: string;
}
export interface IAttachment {
  checked: boolean;
  url?: string;
}

export class TicketLayout implements ITicketLayout {
  _id: string;
  name: string;
  options: IOptionsTicket;
  default: boolean;
  archived: null | string;
  iconUrl: string;
  constructor(data: any = {}) {
    this._id = data.couchDbId || data._id || '';
    this.name = data.name || '';
    this.options = new OptionsTicket(data.options);
    this.default = data.default || false;
    this.archived = data.archived || '';
    this.iconUrl = data.iconUrl || '';
  }
}

export class OptionsTicket implements IOptionsTicket {
  coverSheet: IAttachment;
  drawings: IAttachment;
  description: IAttachment;
  customFields: IAttachment;
  picture: IPicture;
  locationMap: IAttachment;
  startDate: IAttachment;
  dueDate: IAttachment;
  timeline: IAttachment;
  responsible: IAttachment;
  consultedInformed: IAttachment;
  attachments: IAttachment;
  preventPageBreak: IAttachment;
  ticketPageBreak: IAttachment;
  constructor(data: any = {}) {
    this.coverSheet = new Attachment(data.coverSheet);
    this.drawings = new Attachment(data.drawings);
    this.description = new Attachment(data.description);
    this.customFields = new Attachment(data.customFields);
    this.picture = new Picture(data.picture, pictureIcon);
    this.locationMap = new Attachment(data.locationMap, locationMapIcon);
    this.startDate = new Attachment(data.startDate, startDateIcon);
    this.dueDate = new Attachment(data.dueDate, dueDateIcon);
    this.timeline = new Timeline(data.timeline, timelineIcon, data.timelineType);
    this.responsible = new Attachment(data.responsible, responsibleIcon);
    this.consultedInformed = new Attachment(data.consultedInformed, cAndIIcon);
    this.attachments = new Attachment(data.attachments, attachmentIcon);
    this.preventPageBreak = new Attachment(data.preventPageBreak === undefined ? true : data.preventPageBreak);
    this.ticketPageBreak = new Attachment(data.ticketPageBreak === undefined ? false : data.ticketPageBreak);
  }
}

export class Picture implements IPicture {
  checked: boolean;
  pictureType: IPictureSizeClass;
  pictureSize: IPictureSizeClass;
  url: string;
  constructor(data: any = {}, url = '') {
    this.checked = data?.checked || data?.enabled || false;
    this.pictureType = new PictureSizeClass(data?.pictureType) || new PictureSizeClass();
    this.pictureSize = new PictureSizeClass(data?.pictureSize) || new PictureSizeClass();
    this.url = url || '';
  }
}
export class Timeline implements ITimeline {
  checked: boolean;
  timeLineType: IPictureSizeClass;
  url: string;
  constructor(data: any = {}, url = '', type = '') {
    if (data.hasOwnProperty('checked')) {
      this.checked = data.checked || data.enabled || false;
      this.timeLineType = new TimelineTypeClass(data.timeLineType, type) || new TimelineTypeClass();
    } else {
      this.checked = data || false;
      this.timeLineType = new TimelineTypeClass({}, type);
    }
    this.url = url || '';
  }
}

export class TimelineTypeClass implements IPictureSizeClass {
  value: string;
  constructor(data: any = {}, type = '') {
    this.value = type || data.value || 'with-comments';
  }
}
export class PictureSizeClass implements IPictureSizeClass {
  value: string;
  constructor(data: any = {}) {
    this.value = data ? data.value || data : '';
  }
}

class Attachment implements IAttachment {
  checked: boolean;
  url: string;
  constructor(data: any = {}, url = '') {
    this.checked = data || false;
    this.url = url || '';
  }
}

// Audit INterface and class
export interface IAuditLayout {
  _id: string;
  name: string;
  options: IOptionsAudit;
  default: boolean;
  archived: null | string;
  iconUrl: string;
}
interface IOptionsAudit {
  coverSheet: IAttachment;
  drawings: IAttachment;
  dueDate: IAttachment;
  informed: IAttachment;
  tickets: IAttachment;
  attachments: IAttachment;
  timeline: IAttachment;
  ticketOptions: string;
  picture: IPicture;
  preventPageBreak: IAttachment;
}

export class AuditLayout implements IAuditLayout {
  _id: string;
  name: string;
  options: IOptionsAudit;
  default: boolean;
  archived: null | string;
  iconUrl: string;
  constructor(data: any = {}) {
    this._id = data.couchDbId || data._id || '';
    this.name = data.name || '';
    this.options = new OptionsAudit(data.options);
    this.default = data.default || false;
    this.archived = data.archived || '';
    this.iconUrl = data.iconUrl || '';
  }
}
class OptionsAudit implements IOptionsAudit {
  coverSheet: IAttachment;
  drawings: IAttachment;
  showNAQuestions: IAttachment;
  dueDate: IAttachment;
  informed: IAttachment;
  tickets: IAttachment;
  picture: IPicture;
  attachments: IAttachment;
  timeline: IAttachment;
  preventPageBreak: IAttachment;
  ticketOptions: string;

  constructor(data: any = {}) {
    this.coverSheet = new Attachment(data.coverSheet);
    this.drawings = new Attachment(data.drawings);
    this.showNAQuestions = new Attachment(data.showNAQuestions === false ? false : true);
    this.dueDate = new Attachment(data.dueDate, dueDateIcon);
    this.informed = new Attachment(data.informed, informedIcon);
    this.attachments = new Attachment(data.attachments, attachmentIcon);
    this.tickets = new Attachment(data.tickets, ticketsIcon);
    this.timeline = new Timeline(data.timeline, timelineIcon, data.timelineType);
    this.ticketOptions = data.ticketOptions || data.ticketLayoutId || '';
    this.picture = new Picture(data.picture, pictureIcon);
    this.picture.checked = true;
    if (this.picture.pictureSize.value !== 'big') {
      this.picture.pictureSize.value = 'small';
    }
    this.preventPageBreak = new Attachment(data.preventPageBreak === undefined ? true : data.preventPageBreak);
  }
}
export interface IEnabledPlugin {
  name: string;
  url: string;
  iconUrl: string;
  iframeUrl: string;
  id: string;
}
export class EnabledPlugin implements IEnabledPlugin {
  name: string;
  url: string;
  iframeUrl: string;
  iconUrl: string;
  id: string;
  constructor(data: any = {}) {
    this.name = data.name || '';
    this.url = data.url || '';
    this.iconUrl = data.iconUrl || '';
    this.id = data.id || '';
    this.iframeUrl = '';
  }
}
export interface IPrintoptions {
  // Common interface for all audit and ticket print
  language: string;
  project: string;
  mapPrint: boolean;
  user: string;
  channelId: string;
  filename: string;
  time: string;
  frontPage: boolean;
  timeLine: boolean;
  timeLineType: string;
  dueDate: boolean;
  attachments: boolean;
  timeLineComment: boolean;
  ticketPrint: boolean;
  preventPageBreak: boolean;
  platform: string;
}
export interface IAuditExport {
  printoptions: IAuditPrintOptions;
}

export interface IAuditPrintOptions extends IPrintoptions {
  auditPrint: boolean;
  showNAQuestions: boolean;
  informed: boolean;
  ticketOptions: AuditTicket;
  audits: string[];
}
export interface ITicketExport {
  printoptions: TicketPrintOptions;
}

export interface ITicketPrintOptions extends IPrintoptions {
  sortBy: string;
  ticketCount: number;
  description: boolean;
  picture: boolean;
  pictureType: string;
  pictureSize: string;
  locationMap: boolean;
  customFields: boolean;
  startDate: boolean;
  consultedInformed: boolean;
  ticketPageBreak: boolean;
  responsible: boolean;
  tickets: string[];
}

export class AuditExport implements IAuditExport {
  printoptions: IAuditPrintOptions;
  constructor(data: any = {}, appState, socket) {
    this.printoptions = new AuditPrintOptions(data, appState, socket);
  }
}
export class AuditPrintOptions implements IAuditPrintOptions {
  language: string;
  project: string;
  mapPrint: boolean;
  user: string;
  channelId: string;
  filename: string;
  time: string;
  frontPage: boolean;
  timeLine: boolean;
  timeLineType: string;
  pictureSize: string;
  picture: boolean;
  dueDate: boolean;
  preventPageBreak: boolean;
  attachments: boolean;
  timeLineComment: boolean;
  showNAQuestions: boolean;
  ticketPrint: boolean;
  auditPrint: boolean;
  informed: boolean;
  ticketOptions: AuditTicket;
  audits: string[];
  platform: string;
  constructor(data: any = {}, appState, socket) {
    this.language = appState.get('user_lang', 'user') || 'en-US';
    this.project = appState.get('active', 'projects') || '';
    this.mapPrint = data.drawings.checked || false;
    this.user = appState.get('id', 'user') || '';
    this.channelId = generateChannelId(appState, socket);
    this.filename = ''; // Note: Value Will be set in component file
    this.time = new Date().toTimeString().split(' ')[0];
    this.frontPage = data.coverSheet.checked || false;
    this.timeLine = data.timeline.checked || false;
    this.timeLineType = data.timeline.timeLineType.value || 'with-comments';
    this.dueDate = data.dueDate.checked || false;
    this.attachments = data.attachments.checked || false;
    this.timeLineComment = getTimelinePermission(appState) || false;
    this.ticketPrint = data.tickets.checked || false;
    this.pictureSize = data.picture.pictureSize.value || 'small';
    this.auditPrint = true; // Always True for audit export
    this.informed = data.informed.checked || false;
    this.preventPageBreak = data.preventPageBreak.checked || false;
    this.showNAQuestions = data.showNAQuestions.checked === false ? false : true;
    this.ticketOptions = new AuditTicket(data.ticketOptions, appState) || new AuditTicket({}, appState);
    this.audits = data.audits || []; // Note: Value Will be set in component file
    this.platform = 'web';
    this.picture = data.picture.checked || true;
  }
}

export class TicketExport implements ITicketExport {
  printoptions: ITicketPrintOptions;
  constructor(data: any = {}, appState, socket) {
    this.printoptions = new TicketPrintOptions(data, appState, socket);
  }
}
export class TicketPrintOptions implements ITicketPrintOptions {
  language: string;
  project: string;
  mapPrint: boolean;
  user: string;
  channelId: string;
  filename: string;
  time: string;
  frontPage: boolean;
  timeLine: boolean;
  timeLineType: string;
  dueDate: boolean;
  attachments: boolean;
  customFields: boolean;
  timeLineComment: boolean;
  ticketPrint: boolean;
  sortBy: string;
  ticketCount: number;
  description: boolean;
  picture: boolean;
  pictureType: string;
  pictureSize: string;
  locationMap: boolean;
  startDate: boolean;
  consultedInformed: boolean;
  preventPageBreak: boolean;
  ticketPageBreak: boolean;
  responsible: boolean;
  tickets: string[];
  platform: string;
  constructor(data: any = {}, appState, socket) {
    this.language = appState.get('user_lang', 'user') || 'en-US';
    this.project = appState.get('active', 'projects') || '';
    this.mapPrint = data.drawings.checked || false;
    this.user = appState.get('id', 'user') || '';
    this.channelId = generateChannelId(appState, socket);
    this.filename = appState.get('allDb.active.projectName', 'projects').replace(/[^a-zA-Z0-9]/g, '') + '_ticket_report';
    this.time = new Date().toTimeString().split(' ')[0];
    this.frontPage = data.coverSheet.checked || false;
    this.timeLine = data.timeline.checked || false;
    this.timeLineType = data.timeline.timeLineType.value || 'with-comments';
    this.dueDate = data.dueDate.checked || false;
    this.customFields = data.customFields.checked || false;
    this.attachments = data.attachments.checked || false;
    this.timeLineComment = getTimelinePermission(appState) || false;
    this.ticketPrint = true; // Always True for ticket export
    this.sortBy = changeSortKeyForPrint(appState.get('sort.key', 'module')) || 'last-modified';
    this.ticketCount = data.ticketCount || 0; // Note: Value Will be set in component file
    this.description = data.description.checked || false;
    this.picture = data.picture.checked || false;
    this.pictureType = data.picture.pictureType.value || '';
    this.pictureSize = data.picture.pictureSize.value || '';
    this.locationMap = data.locationMap.checked || false;
    this.startDate = data.startDate.checked || false;
    this.consultedInformed = data.consultedInformed.checked || false;
    this.preventPageBreak = data.preventPageBreak.checked || false;
    this.ticketPageBreak = data.ticketPageBreak.checked || false;
    this.responsible = data.responsible.checked || false;
    this.tickets = data.tickets || []; // Note: Value Will be set in component file
    this.platform = 'web';
  }
}

//Store API Interface and models
export interface IStore {
  channelId: string;
  database: string;
  accessToken: string;
}

export interface IStoreTicket extends IStore {
  tickets: string[];
}
export interface IStoreAudit extends IStore {
  audits: string[];
}

export class StoreTicket implements IStoreTicket {
  channelId: string;
  database: string;
  accessToken: string;
  tickets: string[];
  constructor(appState, data, socket) {
    this.channelId = generateChannelId(appState, socket) || '';
    this.database = appState.get('active', 'projects') || '';
    this.accessToken = appState.get('accessToken', 'user') || '';
    this.tickets = data || [];
  }
}
export class StoreAudit implements IStoreAudit {
  channelId: string;
  database: string;
  accessToken: string;
  audits: string[];
  constructor(appState, data, socket) {
    this.channelId = generateChannelId(appState, socket) || '';
    this.database = appState.get('active', 'projects') || '';
    this.accessToken = appState.get('accessToken', 'user') || '';
    this.audits = data || [];
  }
}

export interface ITicketExcelExport {
  mailto: string;
  full_export: boolean;
  channelId: string;
  filename: string;
  time: string;
  sortBy: string;
  sortOrder: string;
  ticket_ids: string[];
}

export class TicketExcelExport implements ITicketExcelExport {
  mailto: string;
  full_export: boolean;
  channelId: string;
  filename: string;
  time: string;
  sortBy: string;
  sortOrder: string;
  ticket_ids: string[];
  constructor(data: any = {}, appState, socket) {
    this.mailto = appState.get('id', 'user') || '';
    this.full_export = data.full_export || false;
    this.channelId = generateChannelId(appState, socket) || '';
    this.filename = appState.get('allDb.active.projectName', 'projects').replace(/[^a-zA-Z0-9]/g, '') + '_ticket_report';
    this.time = new Date().toTimeString().split(' ')[0] || '';
    this.sortBy = changeSortKeyForPrint(appState.get('sort.key', 'module')) || '';
    this.sortOrder = appState.get('sort.direction', 'module') || '';
    this.ticket_ids = data.ticket_ids || [];
  }
}
export interface IAuditExcelReport {
  mailto: string;
  full_export: boolean;
  channelId: string;
  filename: string;
  time: string;
  sortBy: string;
  sortOrder: string;
  audit_ids: string[];
}

export class AuditExcelReport implements IAuditExcelReport {
  mailto: string;
  full_export: boolean;
  channelId: string;
  filename: string;
  time: string;
  sortBy: string;
  sortOrder: string;
  audit_ids: string[];
  constructor(data: any = {}, appState, socket) {
    this.mailto = appState.get('id', 'user') || '';
    this.full_export = data.full_export || false;
    this.channelId = generateChannelId(appState, socket) || '';
    this.filename = data.filename || '';
    this.time = new Date().toTimeString().split(' ')[0] || '';
    this.sortBy = changeSortKeyForPrint(appState.get('sort.key', 'module')) || '';
    this.sortOrder = appState.get('sort.direction', 'module') || '';
    this.audit_ids = data.audit_ids || [];
  }
}

export class AuditTicket {
  // Object to create Ticket option within Audit export
  frontPage: boolean;
  mapPrint: boolean;
  description: boolean;
  picture: boolean;
  pictureType?: string;
  pictureSize?: string;
  locationMap: boolean;
  startDate: boolean;
  dueDate: boolean;
  timeLine: boolean;
  timeLineType: string;
  responsible: boolean;
  consultedInformed: boolean;
  timeLineComment: boolean;
  customFields: boolean;
  attachments: boolean;
  constructor(data: any = {}, appState) {
    this.frontPage = false;
    this.mapPrint = data.drawings ? data.drawings.checked : false;
    this.description = data.description ? data.description.checked : false;
    this.picture = data.picture ? data.picture.checked : false;
    this.pictureType = data.picture ? data.picture.pictureType.value : '';
    this.pictureSize = data.picture ? data.picture.pictureSize.value : '';
    if (!data.picture) {
      delete this.pictureType;
      delete this.pictureSize;
    }
    this.locationMap = data.locationMap ? data.locationMap.checked : false;
    this.startDate = data.startDate ? data.startDate.checked : false;
    this.dueDate = data.dueDate ? data.dueDate.checked : false;
    this.customFields = data.customFields ? data.customFields.checked : false;
    this.timeLine = data.timeline ? data.timeline.checked : false;
    this.timeLineType = data.timeline ? data.timeline.timeLineType.value : 'with-comments';
    this.responsible = data.responsible ? data.responsible.checked : false;
    this.timeLineComment = getTimelinePermission(appState) || false;
    this.consultedInformed = data.consultedInformed ? data.consultedInformed.checked : false;
    this.attachments = data.attachments ? data.attachments.checked : false;
  }
}

// Custom Layout Save Interface and models
export interface ICustomLayout {
  database: string;
  name: string;
  archived: null | string;
  dates: IDates;
  content: IContent;
  module: string;
  project: string;
  options: ICustomAuditOptions | ICustomTicketOptions;
  type: string;
}
export interface IContent {
  author: IAuthor;
  lastModifier: IAuthor;
}
export interface ICustomAuditOptions {
  coverSheet: boolean;
  drawings: boolean;
  dueDate: boolean;
  timeline: boolean;
  timelineType: string;
  responsible: boolean;
  informed: boolean;
  attachments: boolean;
  tickets: boolean;
  showNAQuestions: boolean;
  preventPageBreak: boolean;
  ticketLayoutId: string;
  pictureSize: string;
}
export interface ICustomTicketOptions {
  coverSheet: boolean;
  drawings: boolean;
  description: boolean;
  picture: ICustomLayoutPicture;
  locationMap: boolean;
  startDate: boolean;
  dueDate: boolean;
  timeline: boolean;
  timelineType: string;
  responsible: boolean;
  consultedInformed: boolean;
  customFields: boolean;
  preventPageBreak: boolean;
  attachments: boolean;
}

export interface ICustomLayoutPicture {
  enabled: boolean;
  pictureType: string;
  pictureSize: string;
}
export interface ICustomLayoutTimeline {
  enabled: boolean;
  timeLineType: string;
}

export class CustomLayout implements ICustomLayout {
  database: string;
  name: string;
  archived: null | string;
  dates: IDates;
  content: IContent;
  module: string;
  project: string;
  options: ICustomAuditOptions | ICustomTicketOptions;
  type: string;
  constructor(data: any = {}, type, appState) {
    this.database = data.database || appState.get('active', 'projects');
    this.name = data.name || '';
    this.archived = data.archived || null;
    const dateObject = {
      creationDate: getDate('now'),
      lastModifiedDate: getDate('now'),
    };
    this.dates = new Dates(dateObject);
    this.content = new Content({}, appState);
    this.module = type || '';
    this.project = data.project || appState.get('allDb.active.id', 'projects');
    if (type === 'audit') {
      this.options = new CustomAuditOptions(data.options);
    } else {
      this.options = new CustomTicketOptions(data.options);
    }
    this.type = 'IB.EdBundle.Document.PrintLayout';
  }
}

class Content implements IContent {
  author: IAuthor;
  lastModifier: IAuthor;
  constructor(data: any = {}, appState) {
    const userDetail = {
      email: appState.get('id', 'user'),
    };
    this.author = data.author || new Author(userDetail);
    this.lastModifier = data.lastModifier || new Author(userDetail);
  }
}

class CustomAuditOptions implements ICustomAuditOptions {
  coverSheet: boolean;
  drawings: boolean;
  dueDate: boolean;
  timeline: boolean;
  timelineType: string;
  responsible: boolean;
  informed: boolean;
  attachments: boolean;
  tickets: boolean;
  showNAQuestions: boolean;
  preventPageBreak: boolean;
  ticketLayoutId: string;
  pictureSize: string;
  constructor(data: any = {}) {
    this.coverSheet = data.coverSheet.checked || false;
    this.drawings = data.drawings.checked || false;
    this.dueDate = data.dueDate.checked || false;
    this.dueDate = data.dueDate.checked || false;
    this.timeline = data.timeline.checked || false;
    this.timelineType = data.timeline.timeLineType.value || 'with-comments';
    this.responsible = data.responsible || false;
    this.informed = data.informed.checked || false;
    this.preventPageBreak = data.preventPageBreak.checked || false;
    this.attachments = data.attachments.checked || false;
    this.tickets = data.tickets.checked || false;
    this.ticketLayoutId = data.ticketOptions || '';
    this.pictureSize = data.pictureSize || 'small';
    this.showNAQuestions = data.showNAQuestions.checked === false ? false : true;
  }
}

class CustomTicketOptions implements ICustomTicketOptions {
  coverSheet: boolean;
  drawings: boolean;
  description: boolean;
  picture: ICustomLayoutPicture;
  locationMap: boolean;
  startDate: boolean;
  dueDate: boolean;
  timeline: boolean;
  timelineType: string;
  responsible: boolean;
  consultedInformed: boolean;
  customFields: boolean;
  preventPageBreak: boolean;
  ticketPageBreak: boolean;
  attachments: boolean;
  constructor(data: any = {}) {
    this.coverSheet = data.coverSheet.checked || false;
    this.drawings = data.drawings.checked || false;
    this.description = data.description.checked || false;
    this.picture = new CustomLayoutPicture(data.picture);
    this.locationMap = data.locationMap.checked || false;
    this.startDate = data.startDate.checked || false;
    this.dueDate = data.dueDate.checked || false;
    this.timeline = data.timeline.checked || false;
    this.timelineType = data.timeline.timeLineType.value || 'with-comments';
    this.responsible = data.responsible.checked || false;
    this.consultedInformed = data.consultedInformed.checked || false;
    this.preventPageBreak = data.preventPageBreak.checked || false;
    this.ticketPageBreak = data.ticketPageBreak.checked || false;
    this.customFields = data.customFields.checked || false;
    this.attachments = data.attachments.checked || false;
  }
}
class CustomLayoutPicture implements ICustomLayoutPicture {
  enabled: boolean;
  pictureType: string;
  pictureSize: string;
  constructor(data: any = {}) {
    this.enabled = data.checked || false;
    this.pictureType = data.pictureType.value || '';
    this.pictureSize = data.pictureSize.value || '';
  }
}
export class ProjectSet {
  database: string;
  ticket_ids?: Array<string>;
  audit_ids?: Array<string>;
  constructor(data: any = {}) {
    this.database = data.database || '';
    this.ticket_ids = data.ticket_ids || [];
    this.audit_ids = data.audit_ids || [];
  }
}

export interface ITemplateExport {
  printoptions: ITemplatePrintOptions;
}
export interface ITemplatePrintOptions {
  language: string;
  project: string;
  user: string;
  channelId: string;
  filename: string;
  time: string;
  frontPage: boolean;
  templatePrint: boolean;
  informed: boolean;
  templates: string[];
}
export class TemplatePrintOptions implements ITemplatePrintOptions {
  language: string;
  project: string;
  user: string;
  channelId: string;
  filename: string;
  time: string;
  frontPage: boolean;
  templatePrint: boolean;
  informed: boolean;
  templates: string[];
  constructor(data, appState, socket) {
    this.language = appState.get('user_lang', 'user') || 'en-US';
    this.project = appState.get('active', 'projects') || '';
    this.user = appState.get('id', 'user') || '';
    this.channelId = generateChannelId(appState, socket);
    this.filename = `templates_report_${new Date().getTime()}`;
    this.time = new Date().toTimeString().split(' ')[0];
    this.frontPage = true;
    this.templatePrint = true; // Always True for template export
    this.informed = true;
    this.templates = data.templates || []; // Note: Value Will be set in component file
  }
}
export class TemplateExport implements ITemplateExport {
  printoptions: ITemplatePrintOptions;
  constructor(data, appState, socket) {
    this.printoptions = new TemplatePrintOptions(data, appState, socket);
  }
}
