import { sendGetRequest, sendPostRequest, sendEncodedPostRequest, sendPutRequest, sendDeleteRequest } from '../../utils/requestController';
import { apiUrl } from '../../utils/api';
import { IMfaCheck, IRegisterResponse, IRegisterUser, IUpdatePassword, IUserLogin } from '../../models/user';
import { deleteCookie } from '../../utils/utils';
import { getDate } from '../../utils/dateHelper';
import { getCookie } from '../../utils/authenticationController';
import { releaseLicense } from '../../utils/licenseController';

const doesUserExists = (user: { email: string }) => {
  const url = `${apiUrl.v2api}user/isDemoUser`;
  return new Promise<{ isDemoUser: boolean }>(resolve => {
    sendPostRequest(url, user).then(data => {
      resolve(data);
    });
  });
};

const getUserSourceLists = () => {
  const url = `${apiUrl.v2api}registrationConfig`;
  return new Promise((resolve, reject) => {
    sendGetRequest(url)
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
};
const registerUser = (user: IRegisterUser, captchaCode?: string, token?) => {
  const url = `${apiUrl.v1api}users/register-user?code=${captchaCode}`;
  return new Promise<IRegisterResponse>((resolve, reject) => {
    sendPostRequest(url, user, {
      headers: { token: token },
    })
      .then(data => {
        resolve(data);
      })
      .catch((err: IRegisterResponse) => reject(err));
  });
};

const privacyInfo = (url: string) => {
  return new Promise(resolve => {
    fetch(url)
      .then(response => response.text())
      .then(data => resolve(data));
  });
};

const resetUser = (email: string) => {
  const url = `${apiUrl.v1api}users/${email}/send-reset-password`;
  return new Promise<{ result: boolean }>((resolve, reject) => {
    sendPostRequest(url, {})
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
};

const updatePassword = (email: string, data: IUpdatePassword) => {
  const url = `${apiUrl.v1api}users/${email}/update-password`;
  return new Promise<any>((resolve, reject) => {
    sendPostRequest(url, data)
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
};

const updatePrivacy = (data: { email: string; version: string }) => {
  const url = `${apiUrl.v2api}user/updatePrivacy`;
  return new Promise((resolve, reject) => {
    sendPostRequest(url, data)
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
};

const updateUserPreference = (email: string) => {
  const url = `${apiUrl.emailPrefrences}/${email}`;
  let data = {
    digestEmail: 'THREETIMESADAY',
    newsletter: true,
  };
  return new Promise((resolve, reject) => {
    sendPostRequest(url, data)
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
};

const convertObjectToEncodedString = data => {
  let postdata = '';
  if (Object.keys(data).length > 0) {
    Object.keys(data).forEach((key, index) => {
      postdata = postdata + key + '=' + encodeURIComponent(data[key]);
      if (index < Object.keys(data).length - 1) {
        postdata = postdata + '&';
      }
    });
  }
  return postdata;
};

const checkIfValidUser = async email => {
  const url = `${apiUrl.users}/${email}/verify`;
  return new Promise((resolve, reject) => {
    sendGetRequest(url)
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
};

const sendSSODataAPI = async (token, source) => {
  const body = { source, token };
  const url = `${apiUrl.users}/ssoLogin`;
  return new Promise((resolve, reject) => {
    sendPostRequest(url, body)
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
};

const loginUser = async data => {
  data.username = data.username.toLowerCase();
  const url = `${apiUrl.authentication_server}/token`;
  const postObj = convertObjectToEncodedString(data);

  return new Promise<IUserLogin>((resolve, reject) => {
    sendEncodedPostRequest(url, postObj)
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
};

const validatePhoneNumber = data => {
  const url = `${apiUrl.users}/${data.email}/update-phoneNumber`;
  return new Promise((resolve, reject) => {
    sendPostRequest(url, data)
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
};

const updatePhoneNumber = (phone, email) => {
  const data = { phoneNumber: phone };
  const url = `${apiUrl.v2api}user/updatePhoneNumber?email=${encodeURIComponent(email)}`;
  return new Promise((resolve, reject) => {
    sendPutRequest(url, data)
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
};

const resendConfirm = email => {
  const url = `${apiUrl.users}/${email}/reSendConfirmation`;
  return new Promise((resolve, reject) => {
    sendGetRequest(url)
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
};

const checkIfTrustedUser = (token, accessToken) => {
  const url = `${apiUrl.users}/trustedDevice`;
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
      token: token,
      'auth-mfa-token': accessToken,
    },
  };
  return new Promise((resolve, reject) => {
    sendGetRequest(url, config)
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
};

const setRememberMeDetail = userData => {
  const email = userData.user_email,
    remember_user = {};
  remember_user[email] = {
    accToken: userData.access_token,
    refToken: userData.refresh_token,
    scope: userData.scope,
    tokType: userData.token_type,
  };
  // Set 30 days of life span for user to be remembered in browser
  let expiry_date: string | Date = new Date();
  /* 
      millisecond per 30 days
      new Date().gettime() The value returned by the getTime method is,
      number of milliseconds since 1 January 1970 00:00:00.
  */
  expiry_date.setTime(expiry_date.getTime() + 30 * 24 * 60 * 60 * 1000);
  expiry_date = expiry_date.toUTCString();
  /*Remember current user*/

  const secure = window.location.protocol === 'http:' ? '' : ';secure=true;SameSite=Lax';
  document.cookie = 'rem_current=' + JSON.stringify(remember_user) + `;expires=${expiry_date}${secure}`;
};

const phoneNumberVerify = (email, accessToken) => {
  const url = `${apiUrl.v2api}user/verifyPhoneNumber?email=${email}`;
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
  return new Promise((resolve, reject) => {
    sendGetRequest(url, config)
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
};

const confirmUserEmail = (url, data) => {
  return new Promise((resolve, reject) => {
    sendPostRequest(url, data)
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
};

const sendMfa = accessToken => {
  const url = `${apiUrl.users}/send_mfa_token`;
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return new Promise<IMfaCheck>((resolve, reject) => {
    sendPostRequest(url, {}, config)
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
};

const verifyMFA = (accessToken, messageId, otp, source = '') => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'auth-mfa-token': accessToken,
    },
  };
  let url = `${apiUrl.users}/validate_mfa_token/${messageId}?code=${otp}`;
  if (source === 'google') {
    url = url + '&source=' + source;
  }
  return new Promise((resolve, reject) => {
    sendGetRequest(url, config)
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
};

const setUserInfo = async (userInfo, appState) => {
  let browser_language;
  if (appState.get('switch.user', 'user')) {
    appState.set('id', appState.get('switch.user', 'user'), 'user');
    appState.set('accessToken', appState.get('switch.newaccess_token', 'user'), 'user');
  } else {
    appState.set('id', userInfo.user_email, 'user');
    appState.set('accessToken', userInfo.access_token, 'user');
  }
  // Get user docx and update appState accordingly
  if (appState.get('id', 'user')) {
    // Get Privacy detail of the product
    const privacyDoc = await getPrivacyInfo(appState.get('id', 'user'));
    appState.set('privacy_doc', JSON.stringify(privacyDoc), 'user');

    // Get User data
    const data: any = await getUserData(appState.get('id', 'user'));
    storeUserDataToAppState(data, userInfo, privacyDoc, appState);

    // Check if language is fixed or not
    if (data.language.fixed) {
      browser_language = data.language.language;
    } else {
      browser_language = navigator.language || navigator['userLanguage'];
      if (!appState.get('switch.user', 'user') && data.language.language !== browser_language) {
        data.language = {};
        data.language.language = browser_language;
        data.language.fixed = false;
        if (data.dates && data.dates.lastModifiedDate) {
          data.dates.lastModifiedDate = getDate('now');
        } else {
          data['dates'] = {
            lastModifiedDate: getDate('now'),
          };
        }
        const link = apiUrl.v2api + 'user';
        sendPutRequest(link, data); // Update User docx with browser_language
      }
    }
    appState.set('user_lang', browser_language, 'user');
  }
};

const storeUserDataToAppState = (data, userInfo, privacyDoc, appState) => {
  if (appState.get('switch.user', 'user')) {
    appState.set('id', appState.get('switch.user', 'user'), 'user');
    appState.set('accessToken', appState.get('switch.newaccess_token', 'user'), 'user');
  } else {
    appState.set('id', userInfo.user_email, 'user');
    appState.set('accessToken', userInfo.access_token, 'user');
  }
  appState.set('privacy_doc', JSON.stringify(privacyDoc), 'user');
  appState.set('refreshToken', userInfo.refresh_token, 'user');
  appState.set('tokenType', userInfo.token_type, 'user');
  appState.set('userScope', userInfo.user_scope, 'user');
  appState.set('user_lang', data.language.language, 'user');
  if (data.privacy) {
    appState.set('privacyFlag', data.privacy.agreed, 'user');
    appState.set('privacyversion', data.privacy.version, 'user');
  } else {
    appState.set('privacyFlag', false, 'user');
  }
  if (data.userInfo && data.userInfo.name) {
    let name = (data.userInfo.name.firstName || '') + ' ' + (data.userInfo.name.lastName || '');
    name = name.trim();
    appState.set('name', name, 'user');
  }
  if (data.roles && data.roles.indexOf('superadmin') >= 0) {
    appState.set('sAdmin', true, 'user');
  } else {
    appState.set('sAdmin', false, 'user');
  }
  if (!data.language) {
    data.language = {};
  }
  if (data.mfaEnabled) {
    appState.set('mfaEnabled', data.mfaEnabled, 'user');
  } else {
    appState.set('mfaEnabled', false, 'user');
  }
  if (data.userInfo && data.userInfo.phoneNumberVerified) {
    appState.set('phoneNumberVerified', data.userInfo.phoneNumberVerified, 'user');
  } else {
    appState.set('phoneNumberVerified', false, 'user');
  }
  if (data.userInfo.phoneNumber) {
    appState.set('phoneNumber', data.userInfo.phoneNumber, 'user');
  } else {
    appState.set('phoneNumber', null, 'user');
  }
  if (data.userInfo && data.userInfo.appJourney && data.userInfo.appJourney.web) {
    appState.set('appJourney', false, 'user');
  } else {
    appState.set('appJourney', true, 'user');
  }
  if (appState.get('switch', 'user')) {
    appState.set('appJourney', false, 'user');
  }
  // Set demo user
  if (data.userInfo && data.userInfo.isDemoUser) {
    appState.set('isDemoUser', data.userInfo.isDemoUser, 'user');
  } else {
    appState.set('isDemoUser', false, 'user');
  }
};

const getUserData = (email: string) => {
  const url = apiUrl.v2api + 'user/' + email + '/';
  return new Promise((resolve, reject) => {
    sendGetRequest(url)
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
};

const getPrivacyInfo = email => {
  const url = apiUrl.v2api + 'getPrivacyInfo';
  return new Promise(resolve => {
    sendGetRequest(url)
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        if (err.status === 401) {
          // Remember me access token error
          let expiry_date: string | Date = new Date();
          expiry_date.setTime(expiry_date.getTime() + 30 * 24 * 60 * 60 * 1000);
          expiry_date = expiry_date.toUTCString();
          document.cookie = `invalid_rem_user=${email};expires=${expiry_date}`;
          document.cookie = `rem_current=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        }
      });
  });
};

const clearSession = async (appState, navigate?) => {
  const redirect = () => {
    if (navigate) {
      navigate({ pathname: '/auth', search: '?view=login' });
    } else {
      window.location.hash = encodeURI('/auth?view=login');
      window.location.reload();
    }
  };
  const link = apiUrl.users + '/logout',
    known_user = getCookie('rem_current');
  if (known_user) {
    appState.clear();
    // Remove localstorage stored in older version web app
    localStorage.removeItem('edappstate');
    localStorage.removeItem('dashboardState');
    deleteCookie('auth');
    redirect();
  } else {
    if (appState.get('switch', 'user')) {
      await sendPostRequest(link, {});
      appState.set('id', appState.get('switch.current', 'user'), 'user');
      appState.set('accessToken', appState.get('switch.currentaccess_token', 'user'), 'user');
      appState.del('switch', 'user');
    }
    const res = await sendPostRequest(link, {});
    if (res) {
      localStorage.removeItem('edappstate');
      localStorage.removeItem('dashboardState');
      appState.clear();
      deleteCookie('auth');
      redirect();
    }
  }
};

const clearSwithUser = async () => {
  const url = apiUrl.users + '/logout';
  return new Promise<{ result: boolean }>((resolve, reject) => {
    sendPostRequest(url, {})
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
};

const sendUserDeleteMail = async () => {
  const url = apiUrl.v2api + 'user/requestDeletion';
  return new Promise<boolean>((resolve, reject) => {
    sendGetRequest(url)
      .then(() => {
        resolve(true);
      })
      .catch(err => reject(err));
  });
};
const getMarketingData = async (language: string) => {
  const url = apiUrl.v3api + 'marketing/getLoginData?language=' + language;
  return new Promise<boolean>((resolve, reject) => {
    sendGetRequest(url)
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
};

const deleteUserAccount = async url => {
  return new Promise<{ result: boolean }>((resolve, reject) => {
    sendDeleteRequest(url)
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
};

const clearLoggedInUser = (appState, hash) => {
  appState.clear();
  deleteCookie('auth');
  const url = window.location.protocol + '//' + window.location.hostname + '/#' + hash;
  window.location.href = url;
  window.location.reload();
};

const logout = async (appState, navigate?) => {
  let contractId, licenseId;
  if (appState.get('active.licenseId', 'contracts') || appState.get('previous.licenseId', 'contracts')) {
    // If license exist then contract exist, so get the contract
    if (appState.get('previous.licenseId', 'contracts')) {
      contractId = appState.get('previous.contractId', 'contracts');
      licenseId = appState.get('previous.licenseId', 'contracts');
    } else {
      contractId = appState.get('active.contractId', 'contracts');
      licenseId = appState.get('active.licenseId', 'contracts');
    }
    const released = await releaseLicense(contractId, licenseId, appState);
    if (released) {
      clearSession(appState, navigate);
    }
  } else {
    clearSession(appState, navigate);
  }
};

const getPrivacyLinkBrowserLang = () => {
  let link = '';
  let browser_language = navigator.language || navigator['userLanguage'];
  if (browser_language.match(/nl/gi)) {
    browser_language = 'nl';
  } else if (browser_language.match(/de/gi)) {
    browser_language = 'de';
  } else {
    browser_language = 'en';
  }
  link = window.location.protocol + '//' + window.location.hostname + '/iabpprivacy/edcontrols/v004/' + browser_language + '.html';
  return link;
};

const getWebsiteLink = () => {
  let browser_language = navigator.language || navigator['userLanguage'] || 'en';
  if (browser_language.match(/nl/gi)) {
    return 'https://edcontrols.nl/';
  } else if (browser_language.match(/de/gi)) {
    return 'https://edcontrols.de/';
  } else {
    return 'https://edcontrols.co.uk/';
  }
};

export {
  checkIfValidUser,
  loginUser,
  setUserInfo,
  setRememberMeDetail,
  phoneNumberVerify,
  sendMfa,
  doesUserExists,
  registerUser,
  updatePrivacy,
  updateUserPreference,
  resetUser,
  updatePassword,
  verifyMFA,
  validatePhoneNumber,
  confirmUserEmail,
  privacyInfo,
  sendSSODataAPI,
  resendConfirm,
  checkIfTrustedUser,
  updatePhoneNumber,
  logout,
  clearLoggedInUser,
  clearSession,
  clearSwithUser,
  getPrivacyLinkBrowserLang,
  deleteUserAccount,
  sendUserDeleteMail,
  getUserSourceLists,
  getWebsiteLink,
  getMarketingData,
};
