import { IDates } from './dates';
import { IContent } from './content';
import { generateContent, generateDate } from '../utils/utils';
import { IInformed, IParticipants } from './tickets';

export interface IMapList {
  hits: number;
  page: number;
  results: IMap[];
}
export interface IMap {
  _id: string;
  couchDbId: string;
  _rev: string;
  type: string;
  doctrine_metadata: DoctrineMetadata;
  name: string;
  project: string;
  group: string;
  groupId: string;
  renderinfo: Renderinfo;
  archived?: any;
  timeline: Timeline[];
  isSVG: boolean;
  fileInfo: FileInfo;
  _attachments: any;
  dates: IDates;
  content: IContent;
  fileId?: string;
  originalFileName?: string;
  versionId?: string;
  tags?: string[];
  participants?: IParticipants;
}
export interface DoctrineMetadata {
  indexed: boolean;
  indexes: string[];
  associations: string[];
}
export interface Renderinfo {
  file: string;
  date: Date;
  tilerversion: string;
  dpi: number;
  width: number;
  height: number;
  rendertime: number;
  md5: string;
  sha256: string;
}

export interface Actor {
  email: string;
  type: string;
}

export interface Person {
  email: string;
  type: string;
}

export interface Timeline {
  actor: Actor;
  person: Person;
  operation: string;
  time: Date;
  tilerserver: string;
}
export interface GeoBoundary {
  x: number | string;
  y: number | string;
  latitude: number | string;
  longitude: number | string;
}
export interface FileInfo {
  version: number;
  geoOverlay: GeoOverlay;
  fileUrl: string;
  geoBoundaries?: GeoBoundary[];
}
// TODO: Set up Geooverlay interface
export interface GeoOverlay {}

export class Map implements IMap {
  public _id: string;
  public _rev: string;
  public type: string;
  public doctrine_metadata: DoctrineMetadata;
  public name: string;
  public project: string;
  public group: string;
  public groupId: string;
  public renderinfo: Renderinfo;
  public archived?: any;
  public timeline: Timeline[];
  public isSVG: boolean;
  public fileInfo: FileInfo;
  public _attachments: any;
  public couchDbId: string;
  public dates: IDates;
  public content: IContent;
  constructor(data: any = {}) {
    this._id = data._id;
    this._rev = data._rev;
    this.type = data.type;
    this.doctrine_metadata = data.doctrine_metadata;
    this.name = data.name;
    this.project = data.project;
    this.group = data.group;
    this.groupId = data.groupId;
    this.renderinfo = data.renderinfo;
    this.archived = data.archived;
    this.timeline = data.timeline;
    this.isSVG = data.isSVG;
    this.fileInfo = data.fileInfo;
    this._attachments = data._attachments;
    this.couchDbId = data.couchDbId;
    this.dates = data.dates;
    this.content = data.content;
  }
}
export class GeoParticipants {
  public reporter: IInformed[];
  public type: string;
  constructor(data: any = {}) {
    this.reporter = data.reporter || [];
    this.type = data.type || 'IB.EdBundle.Document.Participants';
  }
}
export class GeoMap {
  public _id: string;
  public type: string;
  public doctrine_metadata: DoctrineMetadata;
  public name: string;
  public project: string;
  public group: string;
  public groupId: string;
  public mapType: string;
  public renderinfo: Renderinfo;
  public archived?: any;
  public timeline: Timeline[];
  public isSVG: boolean;
  public _attachments: any;
  public dates: IDates;
  public content: IContent;
  public participants?: any;
  constructor(data: any = {}, appState) {
    this._id = data._id || 'EDGeomapMapID';
    this.type = data.type || 'IB.EdBundle.Document.Map';
    this.doctrine_metadata = data.doctrine_metadata || {
      associations: ['project'],
      indexed: true,
      indexes: ['name'],
    };
    this.name = data.name || 'Geo Map';
    this.project = data.project || appState.get('allDb.active.id', 'projects');
    this.group = data.group || 'GeoMap';
    this.groupId = data.groupId || 'EDGeomapGroupID';
    this.renderinfo = data.renderinfo || {};
    this.archived = data.archived || null;
    this.timeline = data.timeline || [];
    this.isSVG = data.isSVG || false;
    this.mapType = data.mapType || 'GEO';
    this._attachments = data._attachments || {};
    this.dates = data.dates || generateDate({}, appState);
    this.content = data.content || generateContent({}, appState);
    this.participants = data.participants || new GeoParticipants({});
  }
}

export const setDefaultGeoBoundary: GeoBoundary[] = [
  { x: '', y: '', latitude: '', longitude: '' },
  { x: '', y: '', latitude: '', longitude: '' },
  { x: '', y: '', latitude: '', longitude: '' },
  { x: '', y: '', latitude: '', longitude: '' },
];

export const initialGeoValues = {
  disableSave: true,
  showCoordinates: false,
  tileCoord: [],
  geoBoundaries: setDefaultGeoBoundary,
  point_count: 0,
};
