import * as React from 'react';
import { SnackbarDialog, SnackbarOptions } from './SnackbarDialog';

const SnackbarServiceContext = React.createContext<(options: SnackbarOptions) => Promise<void>>(Promise.reject);
export const useSnackbar = () => React.useContext(SnackbarServiceContext);

export const SnackbarServiceProvider = ({ children }) => {
  const [snackbarState, setSnackbarState] = React.useState<SnackbarOptions | null>(null);
  const awaitingPromiseRef = React.useRef<{
    resolve: () => void;
    reject: () => void;
  }>();

  const openSnackbar = (options: SnackbarOptions) => {
    setSnackbarState(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = (type?) => {
    let temp = Object.assign({}, snackbarState);
    temp.open = false;
    setSnackbarState(temp);
    if (!type && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
    setSnackbarState(null);
  };

  return (
    <>
      <SnackbarServiceContext.Provider value={openSnackbar} children={children} />
      <SnackbarDialog open={snackbarState?.open || false} onSubmit={handleSubmit} onClose={handleClose} title="" {...snackbarState}></SnackbarDialog>
    </>
  );
};
