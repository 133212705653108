import 'vanilla-cookieconsent/dist/cookieconsent.js';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import { useEffect } from 'react';

/* eslint-disable react-hooks/exhaustive-deps */

export function CookieConsent({ lang, langKey }) {
  useEffect(() => {
    // @ts-ignore
    const cookieconsent = window.initCookieConsent();

    cookieconsent.run({
      current_lang: langKey,
      mode: 'opt-in',
      page_scripts: true,
      languages: {
        en: {
          consent_modal: {
            title: lang.cookie_consent.title,
            description: lang.cookie_consent.description,
            primary_btn: {
              text: lang.cookie_consent.primary_btn,
              role: 'accept_all', // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: lang.cookie_consent.secondary_btn,
              role: 'settings', // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: lang.cookie_settings.title,
            save_settings_btn: lang.cookie_settings.save_btn,
            accept_all_btn: lang.cookie_settings.accept_all_btn,
            blocks: [
              {
                title: lang.cookie_settings.block_1_title,
                description: lang.cookie_settings.block_1_description,
              },
              {
                title: lang.cookie_settings.block_2_title,
                description: lang.cookie_settings.block_2_description,
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true,
                },
              },
              {
                title: lang.cookie_settings.block_3_title,
                description: lang.cookie_settings.block_3_description,
                toggle: {
                  value: 'analytics',
                  enabled: true,
                  readonly: false,
                },
              },
              {
                title: lang.cookie_settings.block_4_title,
                description: lang.cookie_settings.block_4_description,
              },
            ],
          },
        },
      },
      gui_options: {
        consent_modal: {
          layout: 'cloud', // box/cloud/bar
          position: 'bottom center', // bottom/middle/top + left/right/center
          transition: 'slide', // zoom/slide
          swap_buttons: false, // enable to invert buttons
        },
        settings_modal: {
          layout: 'box', // box/bar
          // position: 'left',           // left/right
          transition: 'slide', // zoom/slide
        },
      },
    });
  }, []);

  return null;
}
