import { config } from './config';

export const generateQueryParam = options => {
  let url = '',
    query_strings;
  if (options) {
    query_strings = Object.keys(options);
    Object.keys(options).forEach((key, index) => {
      if (Array.isArray(options[key]) && options[key].length > 0) {
        if (query_strings.length > index && url !== '') {
          url += '&';
        }
        url += key + '=' + encodeURIComponent(options[key]);
      } else if (!Array.isArray(options[key]) && options[key]) {
        if (query_strings.length > index && url !== '') {
          url += '&';
        }
        url += key + '=' + encodeURIComponent(options[key]);
      }
    });
    return url;
  }
};

export const getAppliedFilters = (filterObject, location) => {
  let params = new URLSearchParams(location.search);
  Object.keys(filterObject).forEach(key => {
    if (Array.isArray(filterObject[key])) {
      const queryData = params.get(key);
      if (queryData) {
        filterObject[key] = queryData?.split(',');
      }
    } else {
      if (params.get(key)) {
        if (typeof filterObject[key] === 'boolean') {
          filterObject[key] = params.get(key) === 'true';
        } else {
          filterObject[key] = decodeURIComponent(params.get(key).replace(/%/g, '%25'));
        }
      }
    }
  });
  return filterObject;
};

export const getCurrentModule = (path: string): string | false => {
  const toggleFeaturePath = ['projects', 'projects/new', 'projects/edit', 'auth', 'contracts', 'share'];

  let currentModule: string | string[] = path.slice(1);

  if (currentModule && currentModule !== '401' && currentModule !== '404') {
    currentModule = currentModule.split('?')[0];

    if (toggleFeaturePath.includes(currentModule) || currentModule.indexOf('projects/edit') > -1) {
      return currentModule;
    } else if (currentModule.indexOf('contracts/') > -1 || currentModule.indexOf('user-settings') > -1) {
      return currentModule.split('/')[0];
    } else {
      currentModule = currentModule.split('/')[2];

      switch (currentModule) {
        case 'tickets':
          return 'ticket';
        case 'audits':
          return 'audit';
        case 'view-info':
          return 'view-info';
        case 'audit_ticket':
          return 'ticket';
        case 'audit_template':
          return 'audit';
        case 'audit_map':
        case 'maps':
          return 'maps';
        case 'templates':
          return 'template';
        case 'reporter':
          return 'reporter';
        default:
          return false;
      }
    }
  } else {
    if (currentModule === '401' || currentModule === '404') {
      return 'errorComponent';
    } else if (path === '/' || path === '') {
      return 'auth';
    }
    return false;
  }
};

export const updateUrlBasedOnFilter = (filterData, databaseId, type, databaseIds, state, navigate) => {
  const database = databaseIds || databaseId;
  const queryString = generateQueryParam(filterData);
  if (queryString) {
    if (type === 'projects') {
      navigate(`/projects?${queryString}`);
    } else {
      navigate(
        {
          pathname: `/projects/${database}/${type}`,
          search: queryString,
        },
        {
          state: state,
        },
      );
    }
  } else {
    if (type === 'projects') {
      navigate(`/projects?${queryString}`);
    } else {
      navigate(
        {
          pathname: `/projects/${database}/${type}`,
        },
        {
          state: state,
        },
      );
    }
  }
};

export const updateUrl = <T>(filterData: T, navigate, databaseId: string, type: string, state?) => {
  const queryString = generateQueryParam(filterData);
  if (queryString) {
    if (type === 'projects') {
      navigate(`/projects?${queryString}`);
    } else {
      navigate(
        {
          pathname: `/projects/${databaseId}/${type}`,
          search: queryString,
        },
        {
          state: state,
        },
      );
    }
  } else {
    navigate(
      {
        pathname: `/projects/${databaseId}/${type}`,
      },
      {
        state: state,
      },
    );
  }
};

export const getAPIQueryParam = (queryTerm, type?) => {
  const params = new URLSearchParams(queryTerm);
  if (type === 'ticket' && params.get('aggs_type') === 'map' && params.get('aggs_value') && params.get('aggs_value') !== 'EDGeomapMapID' && !params.get('mapId')) {
    const value = params.get('aggs_value') || '';
    params.append('mapId', value);
  }
  if (type === 'ticket') {
    const aggs_type = params.get('aggs_type');
    if (aggs_type) {
      if (aggs_type !== 'map' && aggs_type !== 'group') {
        params.delete('groupId');
        params.delete('mapId');
      } else if (aggs_type !== 'map' && aggs_type === 'group') {
        params.delete('mapId');
      }
    } else {
      params.delete('groupId');
      params.delete('mapId');
    }
  }
  if (type === 'audits') {
    const aggs_type = params.get('aggs_type');
    if (aggs_type !== 'template' && aggs_type !== 'group') {
      params.delete('groupid');
      params.delete('template');
    } else if (aggs_type !== 'template' && aggs_type === 'group') {
      params.delete('template');
    }
  }
  const removableParamforAPI = config.removableParamforAPI;
  removableParamforAPI.forEach(param => {
    if (params.get(param)) {
      params.delete(param);
    }
  });
  return params;
};

export const getProjectIdsFromUrl = (searchString: string) => {
  const params = new URLSearchParams(searchString);
  if (params.get('projects')) {
    const projects = params.get('projects');
    //@ts-ignore
    return projects.split(',');
  }
  return '';
};

export const getProjectIdFromPath = (path: string) => {
  // /projects/com_dutchview_1ce299f2-7499-4685-aa77-69480a5d15b9/maps
  if (!path.includes('/projects/edit') && !path.includes('/contract')) {
    if (path.split('/projects')[1]) return [path.split('/projects/')[1].split('/')[0]];
  } else return [];
};
