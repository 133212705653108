/**
 *
 * NOTE: btnLeftLabel, btnRightLabel, hint tradoura format to be followed is ex: user_guide.project_hint_1 (user_guide is mandatory)
 */

const userGuide = {
  // project: {
  //   headerText: "m_txt_old_usr_a",
  //   gifUrl: require("../../images/user-guide/Getting-Started.gif"),
  //   btnLeftLabel: "m_explore_own",
  //   btnRightLabel: "m_lbl_start_guide",
  //   link: "tickets",
  //   nextType: "ticket",
  //   hint: ["project_hint_1", "project_hint_2"],
  // },
  ticketChooseStep:{
    headerText: "m_lbl_tkts",
    headerIcon: require("../../images/tickets.svg"),
    gifUrl: require("../../images/user-guide/Ticket-intro.gif"),
    link: "maps",
    hint: ["ticket_hint_1", "ticket_hint_2"],
    btnRightLabel: "upload_drawing",
    btnNextGuide: "existing_drawing"
  },
  ticketFindDraw:{
    headerText: "m_lbl_find_drawing",
    headerIcon: require('../../images/search_guide.svg'),
    hint: ["find_drawing_hint_1"],
  },
  libraryIntro: {
    headerText: 'm_lbl_library',
    headerIcon: require('../../images/Library-icon.svg'),
    hint: ['library_intro_hint_1', 'library_intro_hint_2', 'library_intro_hint_3'],
    stepperCount: 4,
    nextType: 'libraryGroup',
    activeStepCountIndex: 0,
    btnRightLabel: 'next_btn',
  },
  libraryGroup: {
    headerText: 'm_lbl_create_lib_grp',
    headerIcon: require('../../images/Tile.svg'),
    gifUrl: require('../../images/user-guide/Ticket_Drawing_group.gif'),
    hint: ['library_group_hint_1', 'library_group_hint_2'],
    stepperCount: 4,
    nextType: 'libraryMap',
    activeStepCountIndex: 1,
  },
  libraryMap: {
    headerText: 'm_lbl_upload_file',
    headerIcon: require('../../images/Tile.svg'),
    gifUrl: require('../../images/user-guide/Ticket_Upload_drawing.gif'),
    hint: ['library_map_hint_1'],
    nextType: 'libraryUpload',
    stepperCount: 4,
    activeStepCountIndex: 2,
  },
  libraryUpload: {
    headerText: 'm_lbl_wait_upload_draw',
    headerIcon: require('../../images/tickets.svg'),
    gifUrl: require('../../images/user-guide/Drawing-uploading.gif'),
    hint: ['library_upload_hint_1', 'library_upload_hint_2'],
  },
  libraryNotification: {
    headerText: 'm_lbl_drawing_ready',
    headerIcon: require('../../images/Tile.svg'),
    hint: ['library_notification_hint_1'],
    btnRightLabel: 'continue',
    link: 'maps',
    nextType: 'librarySelectDrawing',
  },
  librarySelectDrawing: {
    headerText: 'm_lbl_select_map',
    headerIcon: require('../../images/Tile.svg'),
    hint: ['library_select_drawing_hint_1', 'library_select_drawing_hint_2', 'library_select_drawing_hint_3'],
    gifUrl: require('../../images/user-guide/Library-actions.gif'),
    stepperCount: 4,
    activeStepCountIndex: 4,
  },
  ticketLocate: {
    headerText: 'm_lbl_locate_ticket',
    headerIcon: require('../../images/tickets.svg'),
    gifUrl: require('../../images/user-guide/Ticket_Locate.gif'),
    hint: ['ticket_locate_hint_1'],
    stepperCount: 4,
    activeStepCountIndex: 3,
  },
  ticketCreated: {
    headerText: 'm_lbl_tckt_created',
    headerIcon: require('../../images/yey.svg'),
    hint: ['ticket_created_hint_1', 'ticket_created_hint_2'],
    // link: 'audits',
    // nextType: 'audit',
    btnRightLabel: 'checking_tickets',
    btnNextGuide: 'm_lbl_start_audit',
  },
  // ticketLeft: {
  //   headerText: "m_lbl_find_ticket",
  //   headerIcon: require("../../images/audit.svg"),
  //   hint: ["find_ticket_hint_1", "find_ticket_hint_2"],
  //   nextType: "ticketSelect",
  // },
  ticketSelect: {
    headerText: 'tickets_header',
    headerIcon: require('../../images/tickets.svg'),
    nextType: 'ticketComments',
    hint: ['ticket_select_hint_1', 'ticket_select_hint_2'],
    btnRightLabel: 'ok_btn',
  },
  ticketComments: {
    headerText: 'ticket_check_update',
    headerIcon: require('../../images/blue-bell-icon.svg'),
    nextType: 'ticketAssign',
    hint: ['ticket_comment_hint_1', 'ticket_comment_hint_2'],
    stepperCount: 4,
    activeStepCountIndex: 0,
    btnRightLabel: 'next_btn',
    guideRoles: ['consulted'],
  },
  ticketAssign: {
    headerText: 'ticket_assign',
    headerIcon: require('../../images/green-tick-guide.svg'),
    previousType: 'ticketComments',
    hint: ['ticket_assign_hint_1', 'ticket_assign_hint_2'],
    stepperCount: 4,
    activeStepCountIndex: 1,
    btnLeftLabel: 'prev_btn',
    btnRightLabel: 'next_btn',
  },
  ticketStatusChange: {
    headerText: 'ticket_status_change',
    headerIcon: require('../../images/green-rectangle.svg'),
    nextType: 'ticketArchive',
    previousType: 'ticketAssign',
    hint: ['ticket_status_hint_1'],
    stepperCount: 4,
    activeStepCountIndex: 2,
    btnLeftLabel: 'prev_btn',
    btnRightLabel: 'next_btn',
  },
  respnsibleTicketStatusChange: {
    headerText: 'res_ticket_status_change',
    headerIcon: require('../../images/green-rectangle.svg'),
    nextType: 'rciCommentAdded',
    previousType: 'ticketComments',
    hint: ['res_ticket_status_change_hint_1', 'res_ticket_status_change_hint_2'],
    stepperCount: 3,
    activeStepCountIndex: 1,
    btnLeftLabel: 'prev_btn',
    btnRightLabel: 'next_btn',
    guideRoles: ['responsible'],
  },
  rciCommentAdded: {
    headerText: 'm_lbl_comment_added',
    headerIcon: require('../../images/history_guide.svg'),
    previousType: 'respnsibleTicketStatusChange',
    hint: ['comment_added_hint_1', 'comment_added_hint_2'],
    stepperCount: 3,
    activeStepCountIndex: 2,
    btnLeftLabel: 'prev_btn',
    btnRightLabel: 'finish_btn',
    guideRoles: ['responsible', 'consulted', 'informed'],
  },
  informedCheckDetails: {
    headerText: 'm_lbl_check_detail',
    headerIcon: require('../../images/history_guide.svg'),
    nextType: 'audit',
    hint: ['check_detail_hint_1'],
    stepperCount: 1,
    activeStepCountIndex: 0,
    btnRightLabel: 'finish_btn',
    guideRoles: ['informed'],
  },
  reporterTicketCreation: {
    headerText: 'rep_ticket_creation',
    headerIcon: require('../../images/search_guide.svg'),
    nextType: 'ticketLocate',
    hint: ['rep_ticket_creation_hint_1', 'rep_ticket_creation_hint_2'],
    stepperCount: 4,
    activeStepCountIndex: 1,
    guideRoles: ['reporter'],
  },
  reporterTicketCreated:{
    headerText: "m_lbl_tckt_created",
    headerIcon: require('../../images/yey.svg'),
    link: "tickets",
    hint: ['ticket_created_hint_1', 'ticket_created_hint_2'],
    btnRightLabel: 'finish_btn',
    btnNextGuide: 'checking_tickets',
    nextType: 'ticketSelect',
    guideRoles: ['reporter']
  },
  reporterCheckDetails: {
    headerText: 'm_lbl_check_detail',
    headerIcon: require('../../images/history_guide.svg'),
    nextType: 'audit',
    hint: ['repo_check_detail_hint_1'],
    stepperCount: 1,
    activeStepCountIndex: 0,
    btnRightLabel: 'finish_btn',
    guideRoles: ['reporter'],
  },
  ticketArchive: {
    headerText: 'ticket_status_arch_or_save',
    headerIcon: require('../../images/guide-Archive.svg'),
    previousType: 'ticketStatusChange',
    hint: ['ticket_archive_or_save_hint_1', 'ticket_archive_or_save_hint_2'],
    stepperCount: 4,
    activeStepCountIndex: 3,
    link: 'audits',
    nextType: 'audit',
    btnLeftLabel: 'prev_btn',
    btnRightLabel: 'finish_btn',
    btnNextGuide: 'm_lbl_start_audit',
  },

  // Audits module
  audit: {
    headerText: 'm_lbl_audts',
    headerIcon: require('../../images/audit.svg'),
    gifUrl: require('../../images/user-guide/Audit_Intro.gif'),
    hint: ['audit_hint_1', 'audit_hint_2', 'audit_hint_3'],
    link: 'templates',
    // nextType: 'templateGroup',
    btnNextGuide: "use_exis_template",
    btnRightLabel: 'create_template',
  },
  auditTemplateSelect:{
    headerText: "m_lbl_audit_group",
    headerIcon: require('../../images/search_guide.svg'),
    hint: ["audit_group_hint_1"],
  },
  goToAudit: {
    headerText: 'm_lbl_go_to_audit',
    headerIcon: require('../../images/audit.svg'),
    hint: ['go_to_audit_hint_1'],
    stepperCount: 5,
    nextType: 'selectTemplate',
    activeStepCountIndex: 2,
  },
  selectTemplate: {
    headerText: 'm_inf_sel_sub_grp',
    headerIcon: require('../../images/audit.svg'),
    hint: ['select_template_hint_1'],
    stepperCount: 5,
    nextType: 'startAudit',
    activeStepCountIndex: 3,
  },
  startAudit: {
    headerText: 'm_lbl_start_audit',
    headerIcon: require('../../images/audit.svg'),
    gifUrl: require('../../images/user-guide/Audit_Create-Audit.gif'),
    hint: ['start_audit_hint_1'],
    stepperCount: 5,
    nextType: 'auditPageOneEditName',
    activeStepCountIndex: 4,
  },
  auditCreated: {
    headerText: 'm_lbl_audit_created',
    headerIcon: require('../../images/yey.svg'),
    hint: ['audit_create_hint_1', 'audit_create_hint_2'],
    nextType: 'reporter',
    link: 'reporter',
    btnRightLabel: 'm_lbl_start_reporter',
  },
  auditLink: {
    headerText: 'm_lbl_audit_extra_tip',
    headerIcon: require('../../images/TEMPLATE.svg'),
    hint: ['audit_link_hint_1'],
  },
  auditGroup: {
    headerText: 'm_lbl_select_audit_temp',
    headerIcon: require('../../images/search_guide.svg'),
    hint: ['select_audit_temp_hint_1', 'select_audit_temp_hint_2'],
    nextType: 'auditSelect',
    // stepperCount: 2,
    // activeStepCountIndex: 0,
  },
  auditSelect: {
    headerText: 'm_lbl_select_audit',
    // headerIcon: require("../../images/plusCircle.svg"),
    hint: ['select_audit_hint_1', 'select_audit_hint_2'],
    nextType: 'auditPageTwoAnsQues',
    // activeStepCountIndex: 1,
    // stepperCount: 2,
    btnRightLabel: 'ok_btn',
  },
  auditPageOneEditName: {
    headerText: 'm_lbl_audit_name_edit',
    headerIcon: require('../../images/edit_guide.svg'),
    hint: ['audit_edit_name_hint_1'],
    stepperCount: 6,
    nextType: 'auditPageOneAddDrawing',
    activeStepCountIndex: 0,
    btnRightLabel: 'next_btn',
  },
  auditPageOneAddDrawing: {
    headerText: 'm_lbl_ad_mps',
    headerIcon: require('../../images/attachments_guide.svg'),
    hint: ['audit_add_draw_hint_1'],
    stepperCount: 6,
    nextType: 'auditPageOneAddDetails',
    previousType: 'auditPageOneEditName',
    btnLeftLabel: 'prev_btn',
    btnRightLabel: 'next_btn',
    activeStepCountIndex: 1,
  },
  auditPageOneAddDetails: {
    headerText: 'm_lbl_audit_add_details',
    headerIcon: require('../../images/green-tick-guide.svg'),
    hint: ['audit_add_detail_hint_1', 'audit_add_detail_hint_2'],
    stepperCount: 6,
    nextType: 'auditPageOneSave',
    previousType: 'auditPageOneAddDrawing',
    btnLeftLabel: 'prev_btn',
    btnRightLabel: 'next_btn',
    activeStepCountIndex: 2,
  },
  auditPageOneSave: {
    headerText: 'm_lbl_audit_aft_add_details',
    headerIcon: require('../../images/green-tick-guide.svg'),
    hint: ['audit_after_detail_hint_1', 'audit_after_detail_hint_2'],
    stepperCount: 6,
    nextType: 'auditPageTwoAnsQues',
    activeStepCountIndex: 3,
  },
  auditPageTwoAnsQues: {
    headerText: 'm_lbl_audit_team_infor',
    headerIcon: require('../../images/attachments_guide.svg'),
    hint: ['audit_ques_ans_hint_1', 'audit_ques_ans_hint_2'],
    stepperCount: 6,
    activeStepCountIndex: 4,
    nextType: 'auditPageTwoSave',
    btnRightLabel: 'next_btn',
  },
  auditPageTwoSave: {
    headerText: 'm_lbl_audit_compl',
    headerIcon: require('../../images/guide-Archive.svg'),
    hint: ['audit_save_finish_hint_1', 'audit_save_finish_hint_2'],
    stepperCount: 6,
    activeStepCountIndex: 5,
    previousType: 'auditPageTwoAnsQues',
    btnLeftLabel: 'prev_btn',
    btnRightLabel: 'finish_btn',
  },
  auditResInfoPageOneNext: {
    headerText: 'm_lbl_audit_one_next',
    nextType: 'auditPageTwoAnsQues',
    hint: ['audit_one_next_hint_1'],
  },
  auditInformedCheckDetails: {
    headerText: 'm_lbl_check_detail',
    headerIcon: require('../../images/history_guide.svg'),
    nextType: 'audit',
    hint: ['check_detail_audits_hint_1'],
    stepperCount: 1,
    activeStepCountIndex: 0,
    btnRightLabel: 'finish_btn',
    guideRoles: ['informed'],
  },
  auditCompleted: {
    headerText: 'm_lbl_guide_completed_audit',
    headerIcon: require('../../images/audit.svg'),
    hint: ['completed_audit_hint_1', 'completed_audit_hint_2'],
    btnRightLabel: 'finish_btn',
  },

  // Templates module

  templateGroup: {
    headerText: 'm_lbl_create_group',
    headerIcon: require('../../images/TEMPLATE.svg'),
    gifUrl: require('../../images/user-guide/Audit_Template-Group.gif'),
    hint: ['template_group_hint_1', 'template_group_hint_2'],
    stepperCount: 2,
    nextType: 'templateCreate',
    activeStepCountIndex: 0,
  },
  templateCreate: {
    headerText: 'm_lbl_create_template',
    headerIcon: require('../../images/TEMPLATE.svg'),
    hint: ['template_create_hint_1', 'template_create_hint_2'],
    stepperCount: 2,
    nextType: 'goToAudit',
    activeStepCountIndex: 1,
  },
  templateName: {
    headerText: 'm_lbl_add_temp_name',
    headerIcon: require('../../images/TEMPLATE.svg'),
    hint: ['add_temp_name_hint_1'],
    stepperCount: 4,
    activeStepCountIndex: 0,
    nextType: 'templateAuditType',
    btnRightLabel: 'next_btn',
  },
  templateAuditType: {
    headerText: 'm_lbl_select_temp_audit_type',
    headerIcon: require('../../images/TEMPLATE.svg'),
    hint: ['select_temp_audit_type_hint_1', 'select_temp_audit_type_hint_2'],
    stepperCount: 4,
    activeStepCountIndex: 1,
    nextType: 'templateAddCategoryName',
    previousType: 'templateName',
    btnRightLabel: 'next_btn',
    btnLeftLabel: 'prev_btn',
  },
  templateAddCategoryName: {
    headerText: 'm_lbl_add_cat_name',
    headerIcon: require('../../images/TEMPLATE.svg'),
    hint: ['add_cat_name_hint_1', 'add_qstn_hint_1', 'add_qstn_hint_2'],
    stepperCount: 4,
    activeStepCountIndex: 2,
    nextType: 'templateSaveDraftOrPublish',
    previousType: 'templateAuditType',
    btnRightLabel: 'next_btn',
    btnLeftLabel: 'prev_btn',
  },
  // templateAddQstn: {
  //   headerText: "m_lbl_add_qstn",
  //   headerIcon: require("../../images/TEMPLATE.svg"),
  //   hint: ["add_qstn_hint_1", "add_qstn_hint_2"],
  //   stepperCount: 5,
  //   activeStepCountIndex: 1,
  //   nextType: "templateSaveDraftOrPublish",
  //   previousType: "templateAddCategoryName",
  //   btnRightLabel: "next_btn",
  //   btnLeftLabel: "prev_btn",
  // },
  templateSaveDraftOrPublish: {
    headerText: 'm_lbl_temp_save',
    headerIcon: require('../../images/TEMPLATE.svg'),
    hint: ['temp_save_hint_1', 'temp_save_hint_2'],
    stepperCount: 4,
    activeStepCountIndex: 3,
    nextType: 'audit',
    previousType: 'templateAddCategoryName',
    btnRightLabel: 'finish_btn',
    btnLeftLabel: 'prev_btn',
    btnNextGuide: 'm_lbl_start_audit',
  },
  templateImportSelectProj: {
    headerText: 'm_lbl_import_temp_select_proj',
    headerIcon: require('../../images/TEMPLATE.svg'),
    hint: ['import_temp_select_proj_hint_1'],
    stepperCount: 4,
    activeStepCountIndex: 0,
    nextType: 'templateImport',
  },
  templateImport: {
    headerText: 'm_lbl_import_temp',
    headerIcon: require('../../images/TEMPLATE.svg'),
    hint: ['import_temp_hint_1', 'import_temp_hint_2'],
    stepperCount: 4,
    activeStepCountIndex: 1,
    nextType: 'autemplateImportDraftOrPublishdit',
  },
  templateImportDraftOrPublish: {
    headerText: 'm_lbl_import_temp_draft',
    headerIcon: require('../../images/TEMPLATE.svg'),
    hint: ['import_temp_draft_hint_1', 'import_temp_draft_hint_2'],
    stepperCount: 4,
    activeStepCountIndex: 2,
  },
  templateImportSuccess: {
    headerText: 'm_lbl_import_temp_success',
    headerIcon: require('../../images/yey.svg'),
    hint: ['import_temp_success_hint_1'],
    stepperCount: 4,
    activeStepCountIndex: 3,
    btnRightLabel: 'finish_btn',
  },

  // Reporter module
  reporter: {
    headerText: 'm_lbl_reporter',
    headerIcon: require('../../images/CREATOR_black.svg'),
    gifUrl: require('../../images/user-guide/Reporter.gif'),
    hint: ['reporter_hint_1', 'audit_hint_2', 'reporter_hint_2'],
    link: 'reporter',
    nextType: 'reporterAdd',
    btnRightLabel: 'm_lbl_add_new_reporter',
  },
  reporterAdd: {
    headerText: 'm_lbl_add_new_reporter',
    headerIcon: require('../../images/CREATOR_black.svg'),
    hint: ['reporter_add_hint_1', 'reporter_add_hint_2'],
    stepperCount: 3,
    nextType: 'reporterMap',
    activeStepCountIndex: 0,
  },
  reporterMap: {
    headerText: 'm_lbl_select_drawing',
    headerIcon: require('../../images/Tile.svg'),
    hint: ['reporter_map_hint_1'],
    stepperCount: 3,
    nextType: 'reporterCreated',
    activeStepCountIndex: 1,
  },
  reporterCreated: {
    headerText: 'm_lbl_reporter_add',
    headerIcon: require('../../images/yey.svg'),
    hint: ['reporter_created_hint_1', 'reporter_created_hint_2'],
    stepperCount: 3,
    activeStepCountIndex: 2,
  },
};

export default userGuide;
