import { IContent, Content } from './content';
import { IOperation } from './operation';
import { IDates, Dates } from './dates';

export interface IFIle {
  fileName: string;
  attachments: IAttachment[];
  _rev?: string;
  project: string;
  type: string;
  content: IContent;
  archived: string;
  fileGroupID: string;
  couchDbId: string;
  contentType: string;
  thumbnail: string;
  dates: IDates;
  tags: Array<string>;
  deleted: string;
  size: string;
  versions: Array<IVersions>;
  versionId: string;
  _id?: string;
  operation: IOperation[];
  tileStatus?: string;
  name?: string;
}

export interface IVersions {
  contentType: string;
  creationDate: string;
  creator: string;
  fileName: string;
  id: string;
  size: string;
  url: string;
  thumbnail: string;
}

export interface IAttachment {
  content_type: string;
  name: string;
  length: number;
  digest: string;
}
export class File implements IFIle {
  public fileName: string;
  public couchDbId: string;
  public attachments: IAttachment[];
  public _rev?: string;
  public project: string;
  public type: string;
  public content: Content;
  public archived: string;
  public fileGroupID: string;
  public contentType: string;
  public thumbnail: string;
  public dates: IDates;
  public tags: Array<string>;
  public deleted: string;
  public size: string;
  public versions: Array<IVersions>;
  public versionId: string;
  public _id?: string;
  public operation: IOperation[];
  public channelId: string;
  public job: any;
  public time!: string;
  constructor(data: any = {}) {
    this.fileName = data.fileName || '';
    this.attachments = data.attachments || [];
    this._rev = data._rev || '';
    this.couchDbId = data.couchDbId || '';
    this.project = data.project || '';
    this.type = data.type || 'IB.EdBundle.Document.File';
    this.content = data.content || new Content();
    this.archived = data.archived || null;
    this.fileGroupID = data.fileGroupID || '';
    this.contentType = data.contentType || '';
    this.thumbnail = data.thumbnail || null;
    this.dates = data.dates || new Dates();
    this.tags = data.tags || [];
    this.deleted = data.deleted || null;
    this.size = data.size || '';
    this.versions = data.versions || null;
    this._id = data._id || '';
    this.operation = data.operation || [];
    this.versionId = data.versionId;
    this.channelId = data.channelId || null;
    this.job = data.job || null;
  }
}

export const supportedFileTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'image/svg+xml',
  'image/jpeg',
  'image/tiff',
  'image/png',
  'video/mp4',
  'video/quicktime',
  'video/x-ms-wmv',
  'insp',
];

export const getFileExtension = contentType => {
  switch (contentType) {
    case 'application/pdf':
      return 'pdf';
    case 'application/msword':
      return 'doc';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx';
    case 'application/vnd.ms-excel':
      return 'xls';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx';
    case 'application/vnd.ms-powerpoint':
      return 'ppt';
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'pptx';
    case 'image/svg+xml':
      return 'svg';
    case 'image/jpeg':
      return 'jpeg';
    case 'image/jpg':
      return 'jpg';
    case 'image/tiff':
      return 'tiff';
    case 'image/png':
      return 'png';
    case 'video/mp4':
      return 'mp4';
    case 'video/quicktime':
      return 'mov';
    case 'video/x-ms-wmv':
      return 'wmv';
    case 'application/octet-stream':
      return 'ifc';
    case 'text/plain':
      return 'ifc';
  }
};
export interface IFilterLibraryListView {
  group: number;
  person: number;
  tags: number;
}
export class FilterLibraryListView implements IFilterLibraryListView {
  public group: number;
  public person: number;
  public tags: number;
  constructor(data: any = {}) {
    this.group = data.group || 5;
    this.person = data.person || 5;
    this.tags = data.tags || 5;
  }
}

export const filterOptions = [
  { name: 'name', value: 'name' },
  { name: 'last modified', value: 'lastModifiedDate' },
];
