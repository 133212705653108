export const querykey = {
  PROJECTS: 'projects',
  FORMATTEDPROJECT: 'formattedFilters',
  PROJECTLIST:'projectList',
  DASHBOARDACCESS: 'dashboard',
  DMSGROUP: 'dmsGroup',
  PROJECT: 'project',
  LIBRARYTAG: 'libraryTags',
  FILETAGS: 'fileTags',
  MAPTAGS: 'mapTags',
  FILESTACK: 'filestack',
  FILESTACKCONFIG: 'filestackConfig',
  FILE: 'file',
  DOCUMENTS: 'documents',
  TICKETS: 'tickets',
  TAGS: 'tags',
  MAPS: 'maps',
  UNIQUEUSERS: 'uniqueUsers',
  TEMPLATES: 'auditTemplates',
  AUDITTICKETS: 'auditTickets',
  GROUPBASEDMAPS: 'groupBasedMaps',
  AUDITTEMPLATEDATA: 'auditTemplateData',
  TICKETFAVOURITES: 'ticketFavourites',
  MAPGROUPBASED: 'mapgroupbased',
  REPORTERS: 'reporters',
  AUDITS: 'audits',
  TEMPLATEGROUP: 'templateGroup',
  CONTRACTPROJECT: 'contractProject',
  CONTRACTS: 'contracts',
  CONTRACT: 'contract',
  USERDOC: 'userDoc',
  INAPPNOTIF: 'inAppNotifications',
};
