export interface IAppState {
  /**
   * Function to set the application status
   * @param entity is the first key inside edappstate
   * @param key(s) are values inside Entity, nested values can be set by using dot notation
   * @param value: Anything that can be serialized
   */
  set: (key: string, value: any, entity?: string) => void | undefined;
  get: <T = any>(key: string, entity?: string) => T;
  del: (key: string, entity: string) => void;
  clear: () => void;
  initApp: () => void;
  generateUuid: () => string;
}

const storeName = 'edappstate';
const setValue = function (path, value, obj) {
  let fields = path.split('.'),
    result = obj,
    i,
    n,
    field;
  for (i = 0, n = fields.length; i < n && result !== undefined; i += 1) {
    field = fields[i];
    if (i === n - 1) {
      result[field] = value;
    } else {
      if (!result[field]) {
        result[field] = {};
      }
      result = result[field];
    }
  }
  return obj;
};
const getValue = function (path, obj) {
  let fields = path.split('.'),
    result = obj,
    i,
    n,
    field;
  for (i = 0, n = fields.length; i < n && result !== undefined; i += 1) {
    field = fields[i];
    if (i === n - 1) {
      return result[field];
    }
    if (!result[field]) {
      result[field] = {};
    }
    result = result[field];
  }
  return undefined;
};
/*jslint bitwise: true */
const generateUuid = function () {
  let d = new Date().getTime(),
    uuid;
  uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16);
  });
  return uuid;
};
const store = function () {
  let set, get, clear;
  set = function (info, type) {
    if (info) {
      let information;
      information = JSON.stringify(info);
      if (type) {
        localStorage.setItem(storeName, information);
      } else {
        sessionStorage.setItem(storeName, information);
      }
    }
  };
  get = function (type) {
    let info = !type ? sessionStorage[storeName] : localStorage[storeName];
    if (info) {
      info = JSON.parse(info);
      return info;
    }
    return {};
  };
  clear = function (entity) {
    let info = sessionStorage[storeName];
    if (!entity) {
      info = {};
    }
    info = JSON.stringify(info);
    sessionStorage.setItem(storeName, info);
  };
  return { set: set, get: get, clear: clear };
};
const state = function () {
  let initApp, set, get, del, clear;
  // Function to Initilize the application and set the default values
  initApp = function () {
    let status = {},
      entity = 'product';
    status = store().get();
    let deviceId = status[entity] ? status[entity].deviceId : null;
    status[entity] = {};
    status[entity].appType = 'webapp';
    status[entity].version = process.env.REACT_APP_VERSION;
    status[entity].deviceId = deviceId || generateUuid();
    store().set(status);
  };
  /**
   * Function to set the application status
   * Entity is the first key inside edappstate
   * key(s) are values inside Entity, nested values can be set by using dot notation
   * value: Anything that can be serialized
   */
  set = function (key: string, value, entity: string) {
    let status: any = {},
      db,
      type;
    let entityValue = entity;
    if (entityValue === 'contracts' || (entityValue === 'module' && status[entityValue] === 'contracts')) {
      if (key === 'active' || key === 'previous' || key === 'active.contractId' || key === 'active.licenseId' || key === 'previous.contractId' || key === 'previous.licenseId') {
        type = 'localStorage';
      }
    }
    if (entityValue === 'product') {
      if (key === 'deviceId') {
        type = 'localStorage';
      }
    }
    if (entityValue === 'filters' || (entityValue === 'module' && status[entityValue] === 'filters')) {
      type = 'localStorage';
    }
    if (!entityValue) {
      if (key === 'module') {
        status = store().get(type);
        status[key] = value;
        store().set(status, type);
      }
      return undefined;
    }
    status = store().get(type);
    if (entityValue === 'module') {
      entityValue = status[entityValue];
    }
    if (!status[entityValue]) {
      status[entityValue] = {};
    }
    db = status.projects ? status.projects.active : '';
    let keyValue;
    if (key.indexOf('.active') > 0) {
      keyValue = key.replace('active', db);
    }
    // path value object
    status[entityValue] = setValue(keyValue || key, value, status[entityValue]);
    store().set(status, type);
  };
  /**
   * Function to get the application status
   * Entity is the first key inside edappstate
   * key(s) are values inside Entity, nested values can be read by using dot notation
   */
  get = function (key, entity) {
    let status: any = {},
      db,
      type;
    let entityValue = entity,
      keyValue = key;
    if (entityValue === 'contracts' || (entityValue === 'module' && status[entityValue] === 'contracts')) {
      if (key === 'active' || key === 'previous' || key === 'active.contractId' || key === 'active.licenseId' || key === 'previous.contractId' || key === 'previous.licenseId') {
        type = 'localStorage';
      }
    }
    if (entityValue === 'product') {
      if (key === 'deviceId') {
        type = 'localStorage';
      }
    }
    if (entityValue === 'filters' || (entityValue === 'module' && status[entityValue] === 'filters')) {
      type = 'localStorage';
    }
    status = store().get(type);
    if (!entityValue) {
      if (keyValue === 'module') {
        status = status[keyValue];
        return status;
      }
      return undefined;
    }
    if (status) {
      if (entityValue === 'module') {
        entityValue = status[entityValue];
      }
      if (!status[entityValue]) {
        status[entityValue] = {};
      }
      status = status[entityValue];
      if (keyValue) {
        db = status.active || '';
        if (keyValue.indexOf('.active') > 0) {
          keyValue = keyValue.replace('active', db);
        }
        // path object
        status = getValue(keyValue, status);
      } else {
        status = undefined;
      }
      return status;
    } else if (!status && keyValue === 'version') {
      return process.env.REACT_APP_VERSION;
    }
    return undefined;
  };
  // Function to remove Key from a module
  del = function (key, module) {
    let status = {};
    if (key && module) {
      status = store().get() || {};
      if (status[module] && status[module][key]) {
        delete status[module][key];
      }
      store().set(status);
    }
  };
  /**
   * Clears the Session Storage
   */
  clear = function (entity) {
    store().clear(entity);
  };
  return { initApp: initApp, set: set, get: get, del: del, clear: clear };
};
export const appState: IAppState = {
  initApp: state().initApp,
  set: state().set,
  get: state().get,
  del: state().del,
  clear: state().clear,
  generateUuid,
};
