export interface INotification {
  channelID: string;
  status: number;
  time?: string;
  filename?: string;
  type: string;
  isShown: boolean;
  map?: any;
  file?: any;
  link?: string;
}

export class Notifications implements INotification {
  channelID: string;
  status: number;
  time?: string;
  filename?: string;
  type: string;
  isShown: boolean;
  map?: any;
  file?: any;
  link?: string;

  constructor(data: INotification) {
    this.channelID = data.channelID;
    this.status = data.status;
    this.type = data.type;
    this.isShown = data.isShown || false;
    this.filename = data.filename || '';
    this.time = data.time || '';
    this.map = data.map;
    this.file = data.file;
    this.link = data.link;
  }
}
