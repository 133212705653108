import React from 'react';
import io from 'socket.io-client';
import { initializeLanguage } from '../utils/languageController';

// Initialize socket connection with host defined in environment variables or default to current host
const socketHost = process.env.REACT_APP_SOCKET_HOST || window.location.host;
export const socket = io(socketHost, {
  forceNew: true,
  transports: ['websocket'],
});

// Expose socket globally for debugging purposes
window['socket'] = socket;

// Create a context for Socket to be used throughout the application
export const SocketContext = React.createContext(null);

// Initialize language settings using stored user preferences or default to 'en'
let lang = JSON.parse(sessionStorage.getItem('edappstate') || '{}');
let language = {}; // Initialize an empty language object

// Asynchronously initialize language and set translations
initializeLanguage(lang?.user?.user_lang || 'en').then(translations => {
  setLang(translations); // Set the translations once initialized
});

// Function to set language translations
export const setLang = translations => {
  language = { ...translations }; // Update language object with new translations
};

export { language }; // Export the current language object
