import axios from 'axios';
import { generateQueryParam } from './urlController';
let configHeader;
let axiosInstance = axios.create({
  // timeout: 10000,
  headers: {
    // "Content-Type": "application/json",
    Accept: 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  function (config) {
    const storageData = sessionStorage.getItem('edappstate') || '';
    const accessToken = JSON.parse(storageData)?.user?.accessToken || null;
    if (accessToken != null) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  },
);
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return handleError(error.response);
  },
);

export const sendGetRequest = (url, config?) => {
  configHeader = setHeader(config);
  return axiosInstance.get(url, configHeader).then(response => {
    return response.data;
  });
};

export const sendPostRequest = (url, data, config?) => {
  configHeader = setHeader(config);
  return axiosInstance.post(url, data, configHeader).then(response => {
    return response.data;
  });
};
export const sendEncodedPostRequest = (url, data) => {
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  configHeader = setHeader(config);
  return axiosInstance.post(url, data, configHeader).then(response => {
    return response.data;
  });
};

export const sendPutRequest = (url, data, config?) => {
  configHeader = setHeader(config);
  return axiosInstance.put(url, data, configHeader).then(response => {
    return response.data;
  });
};

export const sendDeleteRequest = (url: string, config?: Object) => {
  configHeader = setHeader(config);
  return axiosInstance.delete(url, config).then(response => {
    return response.data;
  });
};

const handleError = error => {
  if (error.status === 401 || error.status === 403 || error.data.status === 401 || error.data.status === 403) {
    redirectToLogin(
      (error.status === 401 || error.data.status === 401) &&
        !window.location.hash.includes('audit_map') &&
        !window.location.hash.includes('audit_ticket') &&
        !window.location.hash.includes('tickets/map'),
    );
  }
  return Promise.reject(error.data || error);
};

export const redirectToLogin = (isRedirecting = false) => {
  sessionStorage.setItem('edappstate', '{}');
  localStorage.setItem('edappstate', '{}');
  const expiry_date = new Date('1 Jan 1920').toUTCString();
  document.cookie = `access_token= ;expires=${expiry_date}`;
  document.cookie = `refresh_token= ;expires=${expiry_date}`;
  document.cookie = `user_scope= ;expires=${expiry_date}`;
  document.cookie = `token_type= ;expires=${expiry_date}`;
  document.cookie = `user_email= ;expires=${expiry_date}`;
  document.cookie = `rem_current= ;expires=${expiry_date}`;
  const queryParams = generateQueryParam({
    view: 'login',
    redirectTo: isRedirecting ? window.location.hash.replace('#', '') : undefined,
  });
  const url = `${window.location.protocol}//${window.location.hostname}/#/auth?${queryParams}`;
  window.location.href = url;
  window.location.reload();
};

const setHeader = config => {
  if (config) {
    return config;
  } else {
    return {
      headers: {
        'content-type': 'application/json',
      },
    };
  }
};
