export interface ICode {
  type: string;
  code: string;
}
export class Code implements ICode {
  public type: string;
  public code: string;
  constructor(data: any = {}) {
    this.type = data.type;
    this.code = data.code;
  }
}

export interface IConnectorItem {
  enabled?: boolean;
  auth_code?: string;
  access_token?: string;
  errMessage?: string;
  lastSync?: string;
}

export interface IConnectorWKI {
  dossierNummer: string;
}

export interface IConnector {
  dropbox?: IConnectorItem;
  sharepoint?: IConnectorItem;
  drive?: IConnectorItem;
  HomeCtrl?: IConnectorItem;
  kyp?: IConnectorItem;
  woningborg?: IConnectorItem;
  hoomCtrlName?: string;
}

export class Connector implements IConnector {
  public dropbox?: IConnectorItem;
  public sharepoint?: IConnectorItem;
  public drive?: IConnectorItem;
  public HomeCtrl?: IConnectorItem;
  public kyp?: IConnectorItem;
  public digiOffice?: IConnectorItem;
  public woningborg?: IConnectorItem;
  public hoomCtrlName?: string;
  public wki: any;
  constructor(data: any = {}) {
    this.dropbox = data ? data.dropbox || new ConnectorItem() : new ConnectorItem();
    this.sharepoint = data ? data.sharepoint || new ConnectorItem() : new ConnectorItem();
    this.drive = data ? data.drive || new ConnectorItem() : new ConnectorItem();
    this.HomeCtrl = data ? data.HomeCtrl || new ConnectorItem() : new ConnectorItem();
    this.kyp = data ? data.kyp || new ConnectorItem() : new ConnectorItem();
    this.digiOffice = data ? data.digiOffice || new ConnectorItem() : new ConnectorItem();
    this.woningborg = data ? data.woningborg || new ConnectorItem() : new ConnectorItem();
    this.hoomCtrlName = data ? data.hoomCtrlName || '' : '';
    this.wki = data ? data.wki || new ConnectorWKI() : new ConnectorWKI();
  }
}

export class ConnectorItem implements IConnectorItem {
  enabled?: boolean;
  auth_code?: string;
  access_token?: string;
  errMessage?: string;
  lastSync?: string;
  constructor(data: any = {}) {
    this.enabled = data.enabled || false;
    this.auth_code = data.auth_code || '';
    this.access_token = data.access_token || '';
    this.errMessage = data.errMessage || '';
    this.lastSync = data.lastSync || '';
  }
}

export class ConnectorWKI implements IConnectorWKI {
  dossierNummer: string;
  enabled?: boolean;
  constructor(data: any = {}) {
    this.dossierNummer = data.dossierNummer || '';
    this.enabled = data.enabled || false;
  }
}
