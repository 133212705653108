import { apiUrl } from '../../utils/api';
import { getRole } from '../../utils/permissionController';
import { sendGetRequest, sendPostRequest, sendPutRequest } from '../../utils/requestController';

// Assets import
import pdfIcon from '../../images/PDF_layout.svg';
import xlsIcon from '../../images/xls.svg';

/**
 * @function getDefaultTicketLayout
 * @description Function to get the default ticket layout
 * @param lang - User language inorder to set the print layout name
 * @returns Array of default ticket layouts
 * @author gaurav.rao
 */
const getDefaultTicketLayout = lang => {
  return [
    {
      _id: 'compact',
      name: lang.m_lbl_cmpct,
      options: {
        drawings: true,
        description: true,
        picture: {
          checked: true,
          pictureType: { value: '1-picture' },
          pictureSize: { value: 'small' },
        },
        preventPageBreak: true,
        ticketPageBreak: false,
        coverSheet: true,
        locationMap: true,
        startDate: true,
        dueDate: true,
        timeline: {
          checked: false,
          timeLineType: { value: 'with-comments' },
        },
        customFields: true,
        responsible: false,
        consultedInformed: false,
        attachments: false,
        checkAll: false,
      },
      default: true,
      archived: null,
      iconUrl: pdfIcon,
    },
    {
      _id: 'detailed',
      name: lang.m_btn_det,
      options: {
        coverSheet: true,
        drawings: true,
        description: true,
        preventPageBreak: true,
        ticketPageBreak: false,
        customFields: true,
        picture: {
          checked: true,
          pictureType: { value: 'all-pictures' },
          pictureSize: { value: 'small' },
        },
        locationMap: true,
        startDate: true,
        dueDate: true,
        timeline: {
          checked: true,
          timeLineType: { value: 'with-comments' },
        },
        responsible: true,
        consultedInformed: false,
        attachments: true,
        checkAll: false, // beta
      },
      default: true,
      archived: null,
      url: null,
      iconUrl: pdfIcon,
    },
    {
      _id: 'excel',
      name: lang.m_btn_exl,
      options: {
        coverSheet: false,
        drawings: false,
        description: false,
        customFields: false,
        picture: {
          checked: false,
          pictureType: { value: '' },
          pictureSize: { value: '' },
        },
        locationMap: false,
        startDate: false,
        dueDate: false,
        timeline: {
          checked: false,
          timeLineType: { value: 'with-comments' },
        },
        responsible: false,
        consultedInformed: false,
        attachments: false,
        checkAll: false,
      },
      default: true,
      archived: null,
      url: null,
      iconUrl: xlsIcon,
    },
  ];
};

/**
 * @function getDefaultAuditLayout
 * @description Function to get the default audit layout
 * @param lang - User language inorder to set the print layout name
 * @returns Array of default audit layouts
 * @author gaurav.rao
 */
const getDefaultAuditLayout = lang => {
  return [
    {
      _id: 'compact',
      name: lang.m_txt_adt_std,
      options: {
        coverSheet: true,
        drawings: true,
        dueDate: true,
        showNAQuestions: true,
        timeline: {
          checked: false,
          timeLineType: { value: 'with-comments' },
        },
        preventPageBreak: true,
        // 'responsible':{'disabled': false,'checked':true},
        informed: true,
        attachments: false,
        tickets: false,
        ticketLayoutId: '',
        pictureSize: 'small',
        checkAll: false,
      },
      default: true,
      archived: null,
      url: null,
      iconUrl: pdfIcon,
    },
    {
      _id: 'detailed',
      name: lang.m_txt_adt_detld,
      options: {
        coverSheet: true,
        drawings: true,
        dueDate: true,
        showNAQuestions: true,
        preventPageBreak: true,
        timeline: {
          checked: true,
          timeLineType: { value: 'with-comments' },
        },
        // 'responsible':{'disabled': false,'checked':true},
        informed: true,
        attachments: true,
        tickets: true,
        ticketLayoutId: 'compact',
        pictureSize: 'small',
        checkAll: true,
      },
      default: true,
      archived: null,
      url: null,
      iconUrl: pdfIcon,
    },
    {
      _id: 'excel',
      name: lang.m_btn_exl,
      options: {
        coverSheet: false,
        drawings: false,
        dueDate: false,
        showNAQuestions: false,
        preventPageBreak: false,
        timeline: {
          checked: false,
          timeLineType: { value: 'with-comments' },
        },
        // 'responsible':{'disabled': true,'checked':false},
        informed: false,
        attachments: false,
        tickets: false,
        ticketLayoutId: '',
        checkAll: false,
      },
      default: true,
      archived: null,
      url: null,
      iconUrl: xlsIcon,
    },
  ];
};

/**
 * @function getPrintOptionslist
 * @description Function to get the layout labels based on the user language logged in.
 * @param lang - User language inorder to set the print layout name
 * @returns Objects with various key value pair to be used for label in print dialog
 * @author gaurav.rao
 */
const getPrintOptionslist = lang => {
  return {
    drawings: lang.m_lbl_maps,
    description: lang.m_lbl_desc,
    picture: lang.m_lbl_picture,
    pictureSize: lang.m_lbl_picture,
    coverSheet: lang.m_lbl_cvr_sheet,
    locationMap: lang.m_lbl_loc_map,
    startDate: lang.m_lbl_start_date,
    dueDate: lang.m_lbl_due_dt,
    timeline: lang.m_lbl_tmelne,
    responsible: lang.m_lbl_resp,
    consultedInformed: lang.m_lbl_cons + '&' + lang.m_lbl_info,
    informed: lang.m_lbl_info,
    tickets: lang.m_lbl_tkts,
    attachments: lang.m_lbl_attach,
    checkAll: lang.m_lbl_un_chk_allchkbox,
    showNAQuestions: lang.print_na_qstn,
    customFields: lang.m_lbl_custom_fields
  };
};

/**
 * @function saveLayout
 * @description Function to save the layout when the user edits the default or custom layout and decides to save it for future use.
 * @param layout - Object with layout information which user selected from the print dialog
 * @param database - The database id of the Project user wants to save the layout information
 * @author gaurav.rao
 */
const saveLayout = (layout, database) => {
  const url = `${apiUrl.v2api}printLayout?database=${database}`;
  return new Promise(resolve => {
    sendPostRequest(url, layout).then(data => {
      resolve(data);
    });
  });
};

/**
 * @function getCustomSavedLayout
 * @description Function to get the custom layout which user has saved earlier if any
 * @param module - Module in which user has selected to print
 * @param database - The database id of the Project user wants to get the layout information
 * @author gaurav.rao
 */
const getCustomSavedLayout = (module: string, database: string) => {
  let url;
  if (module === 'audit') {
    url = `${apiUrl.v2api}printLayout?size=9999&database=${database}`;
  } else {
    url = `${apiUrl.v2api}printLayout?size=9999&database=${database}&module=${module}`;
  }
  return new Promise(resolve => {
    sendGetRequest(url).then(data => {
      resolve(data.results);
    });
  });
};

/**
 * @function getEnabledPlugins
 * @description Function to get the plugins enabled for that particular project/contract
 * @param contractId - The contract id for which to get the plugins enabled
 * @returns Array of plugins that has been enabled
 * @author gaurav.rao
 */
const getEnabledPlugins = (contractId: string) => {
  const url = `${apiUrl.v1api}printPlugin/enabledPlugins?size=9999&contract=${contractId}`;
  return new Promise(resolve => {
    sendGetRequest(url).then(data => {
      resolve(data);
    });
  });
};

/**
 * @function exportPrintPdf
 * @description Function to call the export pdf API on click of Send to me button
 * @param exportData The Object containing various information of the options selected by user to export
 * @author gaurav.rao
 */
const exportPrintPdf = exportData => {
  const url = `${apiUrl.print_server}advanced/`;
  return new Promise((resolve, reject) => {
    sendPostRequest(url, exportData)
      .then(data => {
        resolve(data);
      })
      .catch(error => reject(error));
  });
};

/**
 * @function exportPrintExcel
 * @description Function to call the export excel API on click of Send to me button
 * @param exportExcelData The Object containing various information of the options selected by user to export
 * @param url The url of the excel based on the module selected for excel print that will be used for API call
 * @author gaurav.rao
 */
const exportPrintExcel = (url, exportExcelData) => {
  return new Promise(resolve => {
    sendPostRequest(url, exportExcelData).then(data => {
      resolve(data);
    });
  });
};

/**
 * @function getStorePrintData
 * @description Function to get the access token and related information fot the 3rd party plugin enabled so has enable user to print
 * @param data The Object containing database, EDC access token and array of ids of tickets/audits
 * @author gaurav.rao
 */
const getStorePrintData = data => {
  const url = apiUrl.print_plugins_server + 'store/';
  return new Promise(resolve => {
    sendPostRequest(url, data).then(response => {
      resolve(response);
    });
  });
};

/**
 * @function getSingleLayoutDetail
 * @description Function to get the couchdb docx of the custom layout saved by the user based on id and db name (usecase get data before editing)
 * @param layoutId  Id of the layout user selected to edit/archive
 * @param database The database id of the Project user wants to get the layout information
 * @returns Complete layout object has stored in couchDb
 * @author gaurav.rao
 */
const getSingleLayoutDetail = (layoutId, database) => {
  const url = apiUrl.v2api + 'printLayout/' + layoutId + '?database=' + database;
  return new Promise(resolve => {
    sendGetRequest(url).then(response => {
      resolve(response);
    });
  });
};

/**
 * @function updateSingleLayoutDetail
 * @description Function to update the Custom layout with the edited info ( Archive and dearchive custom layout )
 * @param data Updated layout object with the new or edited values to be saved
 * @param layoutId  Id of the layout user selected to edit/archive
 * @param database The database id of the Project user wants to get the layout information
 * @author gaurav.rao
 */
const updateSingleLayoutDetail = (data, layoutId, database) => {
  const url = apiUrl.v2api + 'printLayout/' + layoutId + '?database=' + database;
  return new Promise(resolve => {
    sendPutRequest(url, data).then(response => {
      resolve(response);
    });
  });
};

/**
 * @function pushKypConnectorTask
 * @description Function to update the connector and send data to kyp based on the user selection within KYP plugin
 * @param data Object/data to be sent to the kyp connector
 * @author gaurav.rao
 */
const pushKypConnectorTask = data => {
  const url = `/api/kyp/projects/${data.id}/${data.data}/${data.planningId}/${data.accessToken}`;
  return new Promise((resolve, reject) => {
    sendGetRequest(url, data)
      .then(response => {
        resolve(response);
      })
      .catch(error => reject(error));
  });
};

/**
 * @function getConnectorTasks
 * @description Function to get all the task or data available for the user for the project for KYP connector
 * @param url Url returned by connector connection to fetch the needed data
 * @author gaurav.rao
 */
const getConnectorTasks = url => {
  return new Promise(resolve => {
    sendGetRequest(url).then(response => {
      resolve(response);
    });
  });
};

/**
 * @function getTimelinePermission
 * @description Function to get the permission on whether to show timeline or not in print based on role of the user in that project
 * @param appState Storage function to get the role of the user
 * @return Boolean which represents show and hide for the timline
 * @author gaurav.rao
 */
const getTimelinePermission = appState => {
  const role: any = getRole('all', appState);
  if (role.accountable) {
    return true;
  } else if (role.superAdmin) {
    return true;
  } else if (role.support) {
    return true;
  } else if (role.contractAdmin) {
    return true;
  } else if (role.rci) {
    return true;
  } else {
    return false;
  }
};

/**
 * @function changeSortKeyForPrint
 * @description Function to get the filter key associated in the print server and satisfy different platform key used to one way
 * @param key Filter type you want to send to the print server
 * @return Filter key that needs to be passed to print server
 * @author gaurav.rao
 */
const changeSortKeyForPrint = function (key) {
  switch (key) {
    case 'duedate':
    case 'DUEDATE':
    case 'due-date':
      return 'due-date';
    case 'author':
    case 'AUTHOR':
      return 'author';
    case 'lastmodifieddate':
    case 'LASTMODIFIEDDATE':
    case 'last-modified':
      return 'last-modified';
    case 'creationdate':
    case 'CREATIONDATE':
    case 'creation-date':
      return 'creation-date';
    case 'title':
    case 'TITLE':
    case 'title-sort':
      return 'title-sort';
    case 'completiondate':
    case 'completed-date':
      return 'completiondate';
    case 'GROUPORMAPNAME':
      return 'drawing-groups-drawing';
    case 'GROUPMAPTICKETNAME':
      return 'drawing';
  }
};

export {
  getDefaultTicketLayout,
  getDefaultAuditLayout,
  getPrintOptionslist,
  getCustomSavedLayout,
  getEnabledPlugins,
  saveLayout,
  getTimelinePermission,
  changeSortKeyForPrint,
  exportPrintPdf,
  getStorePrintData,
  getConnectorTasks,
  exportPrintExcel,
  pushKypConnectorTask,
  getSingleLayoutDetail,
  updateSingleLayoutDetail,
};
