import { apiUrl } from './api';
import { License } from '../models/license';
import { getRole } from './permissionController';
import { ProjectModel } from '../models/project.model';
import { getProjectById } from '../pages/projects/ProjectService';
import { clearSession } from '../pages/authentication/AuthService';
import { sendGetRequest, sendPostRequest } from './requestController';

const claimLicenses = (project, appState) => {
  const url = `${apiUrl.license_server_v2}/clientDocs/${project.contract}/license`;
  const postObj = new License(appState);
  postObj.database = project.projectId;
  return new Promise(resolve => {
    sendPostRequest(url, postObj).then(data => {
      return resolve(data);
    });
  });
};

const claimLicense = (project: ProjectModel, appState) => {
  const url = `${apiUrl.license_server_v2}/clientDocs/${project.contractId}/license`;
  const postObj = new License(appState);
  postObj.database = project.dbName!;
  return new Promise<any>(resolve => {
    sendPostRequest(url, postObj).then(data => {
      return resolve(data);
    });
  });
};

const licenseClaimAttempted = appState => {
  const activeContractId = appState.get('allDb.active.contract', 'projects');
  const licenseContractId = appState.get('active.contractId', 'contracts');
  const activeLicenseId = appState.get('active.licenseId', 'contracts');
  let archivedContr = appState.get('allContr', 'contracts')[licenseContractId];
  archivedContr = archivedContr ? archivedContr.archived : null;

  if ((licenseContractId && activeLicenseId) || archivedContr) {
    if (activeContractId !== licenseContractId && !archivedContr) {
      // for open in New tab
      return false;
    }
    return true;
  }
  return false;
};

const claimLicenseForDirectUrl = async appState => {
  const contractId = appState.get('allDb.active.contract', 'projects'),
    dbName = appState.get('active', 'projects'),
    contract = await appState.get('allContr', 'contracts'),
    projectId = appState.get('allDb.active.couchDbId', 'projects');
  const project: any = await getProjectById(dbName, projectId);
  if (project) {
    project.contractId = contractId;
    appState.set('allDb.active.id', project._id, 'projects');
    appState.set('allDb.active.participants', project.participants || {}, 'projects');
    appState.set('allDb.active.geoMap', project.settings.modules ? project.settings.modules.geomap || false : false, 'projects');
    if (contractId) {
      await contractChanged(contract, project, appState);
      return true;
    }
  }
  return false;
};
// Function to release license
export const releaseLicense = (contract_id, license_id, appState) => {
  if (contract_id && license_id) {
    if (license_id !== 'switch-user-dummy-license') {
      const url = `${apiUrl.license_server_v2}/clientDocs/${contract_id}/release/${license_id}`;

      return sendPostRequest(url, {})
        .then(() => {
          const active = appState.get('active', 'contracts') || {},
            previous = appState.get('previous', 'contracts') || {};

          if (active.contractId === contract_id && active.licenseId === license_id) {
            appState.set('active.licenseId', '', 'contracts');
          }
          if (previous.contractId === contract_id && previous.licenseId === license_id) {
            appState.set('previous.licenseId', '', 'contracts');
          }

          return true;
        })
        .catch(err => {
          //ERR: ""Error in releasing license : " + license_id + " from contract : " + contract_id, err: license_err"
          throw new LicenseError('LicenseError', 'Error in releasing license ' + err);
        });
    } else {
      //if license is dummy one for switch user just remove it
      appState.set('active.licenseId', '', 'contracts');
      return true;
    }
  } else {
    appState.set('active.licenseId', '', 'contracts');
    throw new LicenseError('LicenseError', 'Contract id or license id is missing');
  }
};

// Function To claim and release the license
const claimLicenseAndRelease = async (project: ProjectModel, appState, type?) => {
  const previous = appState.get('previous', 'contracts') || {};
  const licenseContractId = appState.get('active.contractId', 'contracts');
  const archivedContr = appState.get('allContr', 'contracts')[licenseContractId].archived || null;
  if (archivedContr) {
    return true;
  }
  const licenseData = await claimLicense(project, appState);

  if (licenseData) {
    appState.set('active.licenseId', licenseData.licenseId, 'contracts');
    if (type === 'claimOnly') {
      if (licenseData.licenseId) {
        return true;
      } else {
        return false;
      }
    }
    // If license claim is successful, Release the old license
    if (project.contractId !== previous.contractId) {
      if (previous.contractId && previous.licenseId) {
        try {
          const released = await releaseLicense(previous.contractId, previous.licenseId, appState);
          if (released) {
            // Clear the old contract and license information
            appState.set('previous.contractId', '', 'contracts');
            appState.set('previous.licenseId', '', 'contracts');
            return true;
          }
        } catch (exc: any) {
          return exc;
        }
      }
    }
    //Checking
    return true;
  } else {
    return false;
  }
};

/**
 * Contract changed
 */
const contractChanged = async (contracts, project: ProjectModel, appState) => {
  let active = appState.get('active', 'contracts') || {};
  const contractId = project.contractId;

  // For switch user
  if (appState.get('switch.user', 'user')) {
    //If license is claimed release it
    if (active.contractId && active.licenseId) {
      try {
        const released = await releaseLicense(contractId, active.licenseId, appState);
        if (released) {
          // Set dummy license for switched user and don't claim license
          appState.set('active.contractId', contractId, 'contracts');
          appState.set('active.licenseId', 'switch-user-dummy-license', 'contracts');
          return true;
        }
      } catch (exc: any) {
        return exc;
      }
    }
    // Set dummy license for switched user and don't claim license
    appState.set('active.contractId', contractId, 'contracts');
    appState.set('active.licenseId', 'switch-user-dummy-license', 'contracts');
    return true;
  }

  // Check if the contract is same or not
  if (contractId !== active.contractId) {
    /**
     * INFO: "Contract is not same"
     * Store active claimed license information as previous
     *   previous --<--<---<--<-- active
     *   active ->---> Will be claimed next
     */
    appState.set('previous.contractId', active.contractId || '', 'contracts');
    appState.set('previous.licenseId', active.licenseId || '', 'contracts');
    // set Active Contract id to new contract
    appState.set('active.contractId', contractId, 'contracts');
    appState.set('active.licenseId', '', 'contracts');

    let previous = active;

    let admin = false;
    //only accountable, support and manager can claim license
    if (!getRole('', appState)) {
      admin = contracts[contractId].admin;
    }
    if (getRole('', appState) !== 'reporter' && getRole('', appState) !== 'rci' && !admin && getRole('', appState) !== 'contractAdmin' && getRole('', appState) !== 'superAdmin') {
      if (previous.contractId !== contractId) {
        /***
         * Return undefined check
         */
        const claimed = await claimLicenseAndRelease(project, appState);
        if (claimed) return true;
        else return false;
      } else {
        appState.set('active.contractId', previous.contractId, 'contracts');
        appState.set('active.licenseId', previous.licenseId || '', 'contracts');
        return true;
      }
    } else {
      // If RCI roles
      if (contractId === previous.contractId) {
        appState.set('active.licenseId', appState.get('previous.licenseId', 'contracts') || '', 'contracts');
        appState.set('previous.licenseId', '', 'contracts');
        appState.set('previous.contractId', '', 'contracts');
      } else {
        appState.set('active.licenseId', '', 'contracts');
      }
      return true;
    }
  } else {
    if (Object.keys(contracts).length > 0) {
      // To check if there are contracts
      //INFO: "Contract is not changed"
      /* If contract is same, check if license is claimed 
         (Checking because if the user is RCI in first project and no license and 
         now they are support, accountable or administrator in second project license has to be claimed) 
      */

      if (appState.get('active.licenseId', 'contracts')) {
        //INFO: "Has previous license : " + appState.get("active.licenseId", "contracts")"
        return true;
      } else {
        let admin = false;
        //only accountable, support and manager can claim license
        if (!getRole('', appState)) {
          admin = contracts[contractId].admin;
        }
        if (
          getRole('', appState) !== 'reporter' &&
          !admin &&
          getRole('', appState) !== 'rci' &&
          getRole('', appState) !== 'contractAdmin' &&
          appState.get('previous.contractId', 'contracts') !== contractId &&
          getRole('', appState) !== 'superAdmin'
        ) {
          //claim release license call
          const claimed = await claimLicenseAndRelease(project, appState);
          if (claimed) return true;
          else return false;
        }
        //RCI
        else {
          return true;
        }
      }
    } else {
      return false;
      //ERR: "No contracts" "Failed in retrieving contracts"
    }
  }
};

const checkLicenseStatusAPI = async (contractId: string, license: string, email: string, deviceId: string, appState) => {
  const url = `${apiUrl.license_server_v2}/contract/${contractId}/license/${license}/status?email=${encodeURIComponent(email)}&deviceId=${deviceId}&deviceType=web`;
  return new Promise<any>(resolve => {
    sendGetRequest(url).then(async data => {
      if (!data.isLicenseValid) {
        const projectData: any = {
          contractId: contractId,
          dbName: appState.get('active', 'projects'),
        };
        const allContract = appState.get('allContr', 'contracts');
        const getContract = allContract[contractId];
        if (getContract && getContract.licenseType && getContract.licenseType === 'concurrent') {
          const claimed = await claimLicenseAndRelease(projectData, appState, 'claimOnly');
          if (claimed) {
            data.isLicenseValid = true;
            return resolve(data);
          } else {
            return resolve(data);
          }
        } else {
          return resolve(data);
        }
      } else {
        return resolve(data);
      }
    });
  });
};

const setLicenseErrorCookie = message => {
  let expiry_date: string | Date = new Date();
  expiry_date.setTime(expiry_date.getTime() + 30 * 24 * 60 * 60 * 1000);
  expiry_date = expiry_date.toUTCString();
  document.cookie = `invalid_lic_err=${message};expires=${expiry_date}`;
};

const checkLicenseStatus = async appState => {
  const contractId = appState.get('active.contractId', 'contracts');
  const licenseId = appState.get('active.licenseId', 'contracts');
  const email = appState.get('id', 'user');
  const deviceId = appState.get('deviceId', 'product');
  if (contractId && licenseId && !appState.get('switch.user', 'user')) {
    const licenseData = await checkLicenseStatusAPI(contractId, licenseId, email, deviceId, appState);
    if (!licenseData.isLicenseValid) {
      setLicenseErrorCookie(licenseData.isLicenseValid);
      clearSession(appState);
    }
  }
};

const finishLicensePayment = (e, contractId) => {
  if (e.target.nodeName.toLowerCase() === 'u') {
    const url = `${apiUrl.v1api}wps/generatePaymentLink?contractId=${contractId}`;
    return new Promise(resolve => {
      sendGetRequest(url).then(data => {
        window.open(data.url, '_blank', 'noreferrer');
        return resolve(data);
      });
    });
  }
};
class LicenseError implements Error {
  name: string;
  message: string;
  stack: string | undefined;

  constructor(name: string, message: string) {
    this.name = name;
    this.message = message;
  }
}

export { claimLicenses, contractChanged, checkLicenseStatusAPI, setLicenseErrorCookie, checkLicenseStatus, licenseClaimAttempted, claimLicenseForDirectUrl, finishLicensePayment };
