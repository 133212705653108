import React from 'react';

import './NotificationMenu.scss';
import redirectIcon from '../../images/redirect-icon.svg';
import { sendGetRequest, sendPutRequest } from '../../utils/requestController';
import { apiUrl } from '../../utils/api';
import { AppStateContext } from '../..';

import closeIcon from '../../images/close_x_icon.svg';
import dotIcon from '../../images/dot.svg';

type NotificationMenuProps = {
  notifications: any[];
  onClose?: () => void;
};
const NotificationMenu = ({ notifications = [], onClose }: NotificationMenuProps) => {
  const { appState, language: lang } = React.useContext(AppStateContext);

  const handleClose = async () => {
    let email = appState.get('id', 'user');
    const url = `${apiUrl.v2api}user/${email}/`;
    const data = await sendGetRequest(url);

    if (data.latestNotificationVersion - data.viewedNotificationVersion > 0) {
      data.viewedNotificationVersion = data.latestNotificationVersion;
      await sendPutRequest(`${apiUrl.v2api}user/`, data);
    }

    if (onClose) {
      onClose();
    }
  };

  return (
    <div className="rightMenu">
      <div className="rightMenu-container">
        <p className="rightMenu-header">
          {lang.m_ed_updates}
          <img src={closeIcon} alt="close" onClick={handleClose} className="rightMenu-close-icon" />
        </p>
        {notifications.map((notification, index) => (
          <div className="rightMenuData" key={index}>
            <p className="rightMenuData-title">{notification.title}</p>
            <p className="rightMenuData-tag">
              <img src={dotIcon} alt="close" onClick={handleClose} className="rightMenu-subhead-icon" />
              {notification?.tags ? notification?.tags.join(', ') : ''}
            </p>
            <div className="rightMenuData-desc" dangerouslySetInnerHTML={{ __html: notification.description }} />
            <p className="border-bottom"></p>
          </div>
        ))}
        {notifications.length === 0 && (
          <div className="rightMenuData flex-center">
            <p>{lang.txt_upto_date}</p>
          </div>
        )}
        <div className="rightMenu-footer">
          <div className="rightMenu-link" dangerouslySetInnerHTML={{ __html: lang.m_learn_more_link }} />
          <img src={redirectIcon} alt="Learn More" />
        </div>
      </div>
    </div>
  );
};

export default NotificationMenu;
