import React from 'react';
import TextField from '@material-ui/core/TextField/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';

import { AppStateContext } from '../..';
import { switchBackUser, switchUser } from './HeaderService';

type SwitchUserProps = {
  onClose?: () => void;
};

const SwitchUserComponent = (props: SwitchUserProps) => {
  const { language: lang, appState } = React.useContext(AppStateContext);
  const { onClose } = props;

  const invalidEmail = React.useRef<HTMLParagraphElement>(null);
  const [switchUserEmail, setSwitchUserEmail] = React.useState<string>();

  const trySwitchUser = async () => {
    invalidEmail.current.style.display = 'none';
    if (switchUserEmail) {
      if (appState.get('switch.user', 'user')) {
        appState.set('accessToken', appState.get('switch.currentaccess_token', 'user'), 'user');
      }
      const switched = await switchUser(switchUserEmail, appState);
      if (switched) {
        appState.set('active', null, 'projects');
        onClose();
        window.location.reload();
      } else {
        invalidEmail.current.style.display = 'block';
      }
    }
  };

  const switchBack = async () => {
    await switchBackUser(appState);
    onClose();
    appState.set('active', null, 'projects');
    window.location.reload();
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div id="modal-switch-user" className="switch-user-modal-popup" role="dialog">
        <div className="modal-dialog">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close switch-user-pop-up-close" onClick={() => onClose()} data-dismiss="modal">
                &times;
              </button>
              <h4 className="modal-title">{lang.m_lbl_switch_user}</h4>
            </div>
            <div className="modal-body">
              <p ref={invalidEmail} className="invalid-switch-user invalid-switch-user-id" style={{ display: 'none' }}>
                {lang.m_inf_no_user_info}
              </p>
              <TextField
                type="email"
                id="switch-inp-email-id"
                tabIndex={3}
                placeholder={lang.m_lbl_ent_switch_user}
                onChange={e => setSwitchUserEmail(e.target.value.toLowerCase())}
                onKeyUp={e => (e.key === 'Enter' ? trySwitchUser() : null)}
                autoComplete="off"
                error={switchUserEmail === ''}
                helperText={switchUserEmail === '' ? lang.m_err_vald_emal : ''}
                fullWidth
                required></TextField>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default switch-user-btn-cancel"
                onClick={() => switchBack()}
                disabled={!appState.get('switch.current', 'user')}
                data-dismiss="modal">
                {lang.m_lbl_switch_back}
              </button>
              <button type="submit" className="btn btn-primary switch-user-btn-ok" onClick={() => trySwitchUser()}>
                {lang.m_btn_ok}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default SwitchUserComponent;
