export interface IDates {
  creationDate: string;
  lastModifiedDate: string;
  startDate?: string;
  endDate?: string | null;
}

export class Dates implements IDates {
  public creationDate: string;
  public lastModifiedDate: string;
  constructor(data: any = {}) {
    this.creationDate = data.creationDate || '';
    this.lastModifiedDate = data.lastModifiedDate || '';
  }
}

export class ProjectDates implements IDates {
  public creationDate: string;
  public startDate: string;
  public endDate: string | null;
  public lastModifiedDate: string;
  constructor(data: any = {}) {
    this.creationDate = data.creationDate || new Date().toISOString();
    this.startDate = data.startDate || new Date().toISOString();
    this.endDate = data.endDate || null;
    this.lastModifiedDate = data.lastModifiedDate || new Date().toISOString();
  }
}
