import { IActor } from './author';

export class ParticipantsModel implements IParticipants {
  accountable: IActor;
  consulted: IActor[];
  informed: IActor[];
  support: IActor[];
  type: string;
  constructor(data: any = {}) {
    this.accountable = data.accountable || {};
    this.informed = data.informed || [];
    this.support = data.support || [];
    this.consulted = data.consulted || [];
    this.type = 'IB.EdBundle.Document.Participants';
  }
}

export interface IParticipants {
  accountable: IActor;
  consulted: IActor[];
  informed: IActor[];
  support: IActor[];
  type: string;
}
