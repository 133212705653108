export const initializeLanguage = (userLanguage?: string) => {
  let language, fileName;
  const FILE_NAME_PREFIX = 'lang',
    FILE_EXTENSION = '.json',
    supported_lang = ['nl', 'de', 'fr', 'pl', 'sq'];

  if (userLanguage) {
    language = userLanguage;
  } else {
    language = navigator.language || window.navigator['userLanguage'];
  }
  if (language.match(/nl/gi)) {
    language = 'nl';
  }
  if (language.match(/de/gi)) {
    language = 'de';
  }
  if (language.match(/fr/gi)) {
    language = 'fr';
  }
  if (language.match(/sq/gi)) {
    language = 'sq';
  }
  if (language.match(/tr/gi)) {
    language = 'en';
  }
  if (language.match(/es/gi)) {
    language = 'en';
  }
  if (language.match(/zh/gi)) {
    language = 'en';
  }
  if (language.match(/ru/gi)) {
    language = 'en';
  }
  if (language.match(/pl/gi)) {
    language = 'pl';
  }
  if (language.match(/it/gi)) {
    language = 'en';
  }
  language = language.toLowerCase();

  if (supported_lang.indexOf(language) >= 0) {
    fileName = FILE_NAME_PREFIX + '_' + language + FILE_EXTENSION;
  } else {
    fileName = FILE_NAME_PREFIX + FILE_EXTENSION;
  }
  return import(`./../language/${fileName}`).then(response => {
    return response;
  });
};
