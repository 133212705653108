import React from 'react';
import { useLocation } from 'react-router';
import { useQuery } from '@tanstack/react-query';

import { apiUrl } from '../utils/api';
import { AppStateContext } from '../index';
import { querykey } from '../utils/queryHelper';
import { sortObjectData } from '../utils/utils';
import { ProjectModel } from '../models/project.model';
import { getRole } from '../utils/permissionController';
import { getAllMaps } from '../pages/tickets/TicketsService';
import { getTemplatesForAudit } from '../pages/audits/AuditService';
import { getAllUsersEmails } from '../utils/authenticationController';
import { getProjectIdFromPath, getProjectIdsFromUrl } from '../utils/urlController';

interface IEdState {
  isEditing?: boolean;
  isTemplatesFetched?: boolean;
  maps?: any;
  freeTrailGuideData?: any;
  templates?: any;
  project?: ProjectModel;
  personList?: any;
  personListRoles?: any;
  resetQuery?: (key: string) => void;
  stateUpdater?: (action: any) => void;
}

export const EDStateContext = React.createContext<IEdState>({
  personList: {},
  resetQuery: () => null,
  maps: {},
  templates: [],
});

const EDStateReducer = (state: any, action) => {
  switch (action.type) {
    // case 'user': return {...state, user: action.payload};
    case 'maps':
      return { ...state, maps: action.payload };
    case 'project':
      return { ...state, project: action.payload };
    case 'templates':
      return { ...state, templates: action.payload };
    case 'activeProject':
      return { ...state, activeProject: action.payload };
    case 'language':
      return { ...state, language: action.payload };
    case 'leftMenu':
      return { ...state, isLeftMenuCollapsed: action.payload };
    case 'personList':
      return { ...state, personList: sortObjectData(action.payload.users), personListRoles: action.payload.userWithRoles };
  }
};

const userGuideIntialData = localStorage.getItem('userGuideData')
  ? JSON.parse(localStorage.getItem('userGuideData'))
  : {
      isFreeTrial: false,
      type: '',
      isShowFlow: false,
      isViewedOnce: false,
      isTicketFlowDone: false,
      isAuditFlowDone: false,
      isReporterFlowDone: false,
      isGuideSkipped: true,
      guideRoles: '',
    };

export const EDStateControls = ({ children }) => {
  const location: any = useLocation();
  const module = React.useRef('');
  const { appState } = React.useContext(AppStateContext);
  const [isEditing, setIsEditing] = React.useState(false);
  const [freeTrailGuideData, setFreeTrailGuideData] = React.useState(userGuideIntialData);

  const databaseIds = React.useMemo(() => {
    module.current = location.pathname.split('/')[3];
    const allowedModules = ['tickets', 'audits', 'maps', 'templates', 'reporter', 'audit_template', 'audit_ticket', 'audit_map'];
    if (allowedModules.includes(module.current)) {
      return getProjectIdsFromUrl(location.search) || getProjectIdFromPath(location.pathname)!;
    } else return [];
  }, [location.pathname, location.search]);

  /**
   * @returns All users from project(s)
   */
  const { data: personList, refetch: refetchPersonList } = useQuery({
    queryKey: [querykey.UNIQUEUSERS, databaseIds.join()],
    queryFn: () => getAllUsersEmails(databaseIds),
    enabled: databaseIds.length > 0,
    initialData: { users: {}, personListRoles: {} },
  });

  const getMaps = async () => {
    const url = `${apiUrl.v2api}maps/search?database=${databaseIds[0]}&size=99999`;
    let body: any = {
      sortby: 'group',
      sortOrder: 'asc',
      includeFields: ['couchDbId', 'archived', 'database', 'group', 'groupId', 'name', 'participants', 'fileInfo'],
    };
    if (databaseIds.length > 1) {
      body = { ...body, projects: databaseIds };
    }
    if (getRole('rcir', appState) === 'rciReporter' || getRole('rcir', appState) === 'reporter') {
      body['isReporter'] = true;
    }
    if (location.pathname.includes('audits') && location.state) {
      const selectedMaps = location.state.audit ? location.state.audit.maps : [];
      if (selectedMaps.length > 0) {
        const detailsMaps: any = [];
        selectedMaps.map(map => detailsMaps.push(map.mapID));
        body['mapid'] = detailsMaps.join(',');
      }
    }
    return getAllMaps(url, body);
  };

  const { data: maps, refetch: refetchMaps } = useQuery({
    queryKey: [querykey.MAPS, databaseIds.join(), location.search],
    queryFn: getMaps,
    enabled: databaseIds.length > 0,
    // onSuccess: (data) => dispatch({type: 'maps', payload: data})
  });

  const {
    data: templates,
    refetch: refetchTemplates,
    isFetched: isTemplatesFetched,
  } = useQuery({
    queryKey: [querykey.TEMPLATES, databaseIds.join()],
    queryFn: () => getTemplatesForAudit(databaseIds),
    enabled: databaseIds.length > 0,
    // onSuccess: (data) => dispatch({type: 'templates', payload: data})
  });


  const resetQuery = (key: string) => {
    switch (key) {
      case 'maps':
        refetchMaps();
        break;
      case 'personList':
        refetchPersonList();
        break;
      case 'templates':
        refetchTemplates();
        break;
    }
  };

  /**
   * The States
   */
  const initialState: IEdState = {
    isEditing: false,
  };

  const [state, dispatch] = React.useReducer(EDStateReducer, initialState);

  const stateUpdater = (action: { type: any; payload: any }) => {
    switch (action.type) {
      case 'editMode':
        appState.set('edit', action.payload, 'module');
        setIsEditing(action.payload);
        break;
      case 'userGuideData':
        setFreeTrailGuideData(action.payload);
        const node = document.getElementById('user-guide');

        if (node) {
          node.classList.remove('show');
        }
        localStorage.setItem('userGuideData', JSON.stringify(action.payload));
        break;
      default:
        dispatch(action);
    }
  };

  return (
    <EDStateContext.Provider
      value={{
        ...state,
        templates,
        isTemplatesFetched,
        isEditing,
        freeTrailGuideData,
        maps,
        personList: personList?.users,
        personListRoles: personList?.userWithRoles,
        resetQuery,
        stateUpdater,
      }}>
      {children}
    </EDStateContext.Provider>
  );
};
