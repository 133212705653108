/**
 * Object containing URLs for accessing different types of an API and ids.
 * @readonly
 * @constant
 * @type {Object}
 * @author gaurav.rao
 */
export const apiUrl = {
  v1api: '/api/v1/',
  v2api: '/api/v2/data/',
  v3api: '/api/v3/data/',
  users: '/api/v1/users',
  tile_server: '/api/v1/tiles',
  print_server: '/api/v1/print/',
  bouwquote: '/api/v1/bouwquote/',
  secureData: '/api/v1/securedata/',
  statistics: '/api/v1/statistics/',
  couchDBUrl: '/api/v1/securedata/',
  emailPrefrences: '/userpreference',
  analytics: 'api/v1/data/analytics',
  authentication_server: '/api/v1/oauth',
  export_server: '/api/v1/exporttoexcel/',
  license_server_v2: '/api/v2/licenseserver',
  print_plugins_server: '/api/v1/printPlugin/',
  lastUserActivity: '/api/v2/data/user/userActivity',
  inAppNotification: '/api/v2/data/user/getNotifications',

  dropboxClientId: 'nqa7fh7mkvvdvjk',
  microsoftClientId: '34648a7f-b8cd-497e-ac5c-ff73fa1e05d2',
  microsoftTenantId: 'f8cdef31-a31e-4b4a-93e4-5f571e91255a',
  googleClientId: '772750849635-lh3uvbjpi66jgp9kvvo360nog2hof340.apps.googleusercontent.com',
};
