export interface IPerson {
  email: string;
  type: string;
}

export class Person {
  email: string;
  type: string;
  constructor(data: any = {}) {
    this.email = data.email || '';
    this.type = data.type || 'IB.EdBundle.Document.Person';
  }
}

export interface IContent {
  author: any;
  lastModifier: any;
}

export interface IContentModifier {
  author: any;
  lastmodifier: any;
  type?: string;
}

export class Content implements IContent {
  public author: any;
  public lastModifier: any;
  constructor(data: any = {}) {
    this.author = data.author || '';
    this.lastModifier = data.lastModifier || '';
  }
}

export class ContentModifier implements IContentModifier {
  public author: any;
  public lastmodifier: any;
  type?: string;
  constructor(data: any = {}) {
    this.author = data.author || '';
    this.lastmodifier = data.lastmodifier || '';
    this.type = data.type || 'IB.EdBundle.Document.Note';
  }
}

export class ProjectContent extends ContentModifier {
  constructor(data: any = {}) {
    super(data);
    this.author = new Person();
    this.lastmodifier = new Person();
  }
}

export interface IFileContent {
  file_data: string;
  category: string;
}
export class FileContent implements IFileContent {
  public file_data: string;
  public category: string;
  constructor(data: any = {}) {
    this.file_data = data.file_data || '';
    this.category = data.category || '';
  }
}
