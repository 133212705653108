import * as React from 'react';
import { ConfirmationDialog, ConfirmationOptions } from './EDConfirmationDialog';

const EdConfirmationServiceContext = React.createContext<(options: ConfirmationOptions) => Promise<void>>(Promise.reject);
export const useConfirmationDialog = () => React.useContext(EdConfirmationServiceContext);

export const EdConfirmationServiceProvider = ({ children }) => {
  const [confirmationState, setConfirmationState] = React.useState<ConfirmationOptions | null>(null);
  const awaitingPromiseRef = React.useRef<{ resolve: () => void; reject: () => void }>();
  const [open, setOpen] = React.useState<boolean>(false);

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options);
    setOpen(true);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    setOpen(false);
  };
  const handleXClick = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
    setOpen(false);
  };
  const handleClosed = () => {
    setConfirmationState(null);
  };
  return (
    <>
      <EdConfirmationServiceContext.Provider value={openConfirmation} children={children} />
      <ConfirmationDialog
        open={open}
        handleXClick={handleXClick}
        onSubmit={handleSubmit}
        onClose={handleClose}
        onClosed={handleClosed}
        title=""
        description={[]}
        {...confirmationState}></ConfirmationDialog>
    </>
  );
};
