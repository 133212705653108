/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ClickAwayListener } from '@material-ui/core';

import { AppStateContext } from '../../';

import './InAppGuide.scss';

import guide from '../../images/help-icon.svg';
import closeIcon from '../../images/close_icon.svg';
import questionIcon from '../../images/question-icon.svg';

type ProjectGuideProps = {
  setShowProjectGuide: any;
};

const ProjectGuide = ({ setShowProjectGuide }: ProjectGuideProps) => {
  const { language: lang } = React.useContext(AppStateContext);

  const appGuideClose = () => {
    setShowProjectGuide(false);
  };

  const showProjectGuide = () => (
    <ClickAwayListener onClickAway={() => setShowProjectGuide(false)}>
      <div className="proj-guide-component">
        <div className="proj-guide-container">
          <div className="proj-guide-context">
            <span className="header">
              <img src={guide} alt="guide" className="tool-img" />
              <h4 className="proj-guide-header">{lang.m_txt_old_usr_a}</h4>
              <img src={closeIcon} alt="" onClick={appGuideClose} className="close" />
            </span>
            <div className="proj-guide-context1">
              <p>{lang.home_screen_proj_list}</p>
              <p>
                {lang.click_proj_tile}
                <img src={questionIcon} alt="help" className="help-img" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );

  setShowProjectGuide(true);
  return showProjectGuide();
};

export default ProjectGuide;
