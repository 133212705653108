export class FileGroup {
  public access: boolean;
  public create: boolean;
  public edit: boolean;
  public archive?: boolean;
  constructor(data: any = {}) {
    this.access = data.access || false;
    this.create = data.create || false;
    this.edit = data.edit || false;
    this.archive = data.archive || false;
  }
}

export class UserRole implements IUserRole {
  public accountable: boolean;
  public rci: boolean;
  public reporter: boolean;
  public support: boolean;
  public superAdmin: boolean;
  public contractAdmin: boolean;
  public manager: boolean;
  constructor(data: any = {}) {
    this.accountable = data.accountable || false;
    this.rci = data.rci || false;
    this.reporter = data.reporter || false;
    this.support = data.support || false;
    this.superAdmin = data.superAdmin || false;
    this.contractAdmin = data.contractAdmin || false;
    this.manager = data.manager || false;
  }
}

export interface IUserRole {
  accountable: boolean;
  rci: boolean;
  reporter: boolean;
  support: boolean;
  superAdmin: boolean;
  contractAdmin: boolean;
  manager: boolean;
}

export interface IPermissionObject {
  readOnly: boolean;
  print?: boolean;
  ticket?: ITicket;
  audit?: IAudit;
  project?: IProject;
  library?: ILibrary;
  template?: ITemplate;
  modAccess?: IModAccess;
  licActive?: boolean;
  contractActive?: boolean;
}

interface ILibrary {
  create: boolean;
  edit: boolean;
  archive: boolean;
}
interface ITemplate {
  create: boolean;
  edit: boolean;
  archive: boolean;
}

export interface IAudit {
  create: boolean;
  edit: boolean;
  complete: boolean;
  archive: boolean;
  roles: IRoles;
  reopen: boolean;
  dates: boolean;
  tags: boolean;
  delete: boolean;
  maps: boolean;
}

export interface IModAccess {
  map: boolean;
  ticket: boolean;
  reporter: boolean;
  template: boolean;
  audit: boolean;
}

export interface ITicket {
  create: boolean;
  comment: boolean;
  attachment: boolean;
  roles: IRoles;
  dates: boolean;
  status: boolean;
  archive: boolean;
  access: boolean;
  title: boolean;
  description: boolean;
  tags: boolean;
  save: boolean;
  customFields:boolean;
}

export interface IRoles {
  consulted: boolean;
  responsible: boolean;
  informed: boolean;
}

export class Permission implements IPermissionObject {
  public print: boolean;
  public readOnly: boolean;
  public contractActive: boolean;
  public ticket: ITicket;
  public audit: IAudit;
  public modAccess: IModAccess;
  public licActive: boolean;
  public project: IProject;
  public library: ILibrary;
  public template: ITemplate;
  constructor(data: any = {}) {
    this.readOnly = data.readOnly || false;
    this.contractActive = data.contractActive || true;
    this.print = data.print || true;
    this.licActive = data.licActive || false;
    this.ticket = data.ticket || new Ticket();
    this.audit = data.audit || new Audit();
    this.modAccess = data.modAccess || new ModAccess();
    this.project = data.project || new Project();
    this.library = data.library || new Library();
    this.template = data.template || new Template();
  }
}

export class ModAccess implements IModAccess {
  public map: boolean;
  public ticket: boolean;
  public reporter: boolean;
  public template: boolean;
  public audit: boolean;
  constructor(data: any = {}) {
    this.map = data.map || false;
    this.ticket = data.ticket || false;
    this.reporter = data.reporter || false;
    this.template = data.template || false;
    this.audit = data.audit || false;
  }
}
export class Ticket implements ITicket {
  public create: boolean;
  public comment: boolean;
  public attachment: boolean;
  public roles: IRoles;
  public dates: boolean;
  public status: boolean;
  public archive: boolean;
  public access: boolean;
  public title: boolean;
  public description: boolean;
  public tags: boolean;
  public save: boolean;
  constructor(data: any = {}) {
    this.create = data.create || false;
    this.comment = data.comment || false;
    this.attachment = data.attachment || false;
    this.roles = data.roles || new Roles();
    this.dates = data.dates || false;
    this.status = data.status || false;
    this.archive = data.archive || false;
    this.access = data.access || false;
    this.title = data.title || false;
    this.description = data.description || false;
    this.tags = data.tags || false;
    this.save = data.save || false;
  }
}

export class Audit implements IAudit {
  create: boolean;
  edit: boolean;
  complete: boolean;
  archive: boolean;
  read: boolean;
  roles: IRoles;
  reopen: boolean;
  dates: boolean;
  tags: boolean;
  maps: boolean;
  delete: boolean;
  constructor(data: any = {}) {
    this.create = data.create || false;
    this.read = data.read || true;
    this.edit = data.edit || false;
    this.complete = data.complete || false;
    this.archive = data.archive || false;
    this.roles = data.roles || new Roles();
    this.reopen = data.reopen || false;
    this.dates = data.dates || false;
    this.tags = data.tags || false;
    this.maps = data.maps || false;
    this.delete = data.delete || false;
  }
}
interface IProject {
  create: boolean;
  edit: boolean;
  archive: boolean;
}
export class Project implements IProject {
  create: boolean;
  edit: boolean;
  archive: boolean;
  constructor(data: any = {}) {
    this.create = data.create || false;
    this.edit = data.edit || false;
    this.archive = data.archive || false;
  }
}
export class Template implements ITemplate {
  create: boolean;
  edit: boolean;
  archive: boolean;
  constructor(data: any = {}) {
    this.create = data.create || false;
    this.edit = data.edit || false;
    this.archive = data.archive || false;
  }
}
export class Library implements ILibrary {
  create: boolean;
  edit: boolean;
  archive: boolean;
  constructor(data: any = {}) {
    this.create = data.create || false;
    this.edit = data.edit || false;
    this.archive = data.archive || false;
  }
}
export class Roles implements IRoles {
  public consulted: boolean;
  public responsible: boolean;
  public informed: boolean;
  constructor(data: any = {}) {
    this.consulted = data.consulted || false;
    this.responsible = data.responsible || false;
    this.informed = data.informed || false;
  }
}
