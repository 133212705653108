export interface IAuthor {
  type: string;
  email: string;
}

export class Author implements IAuthor {
  type: string;
  email: string;
  constructor(data: any = {}) {
    this.type = data.type || 'IB.EdBundle.Document.Person';
    this.email = data.email || '';
  }
}

export interface IActor {
  email: string;
  type: string;
  interface: string;
  interfaceVersion: string;
}
