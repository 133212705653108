import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ListSubheader, MenuItem } from '@material-ui/core';

import { AppStateContext } from '../..';
import { UserRole } from '../../models/permissions';
import { EDStateContext } from '../../context/EdState';
import { getRole } from '../../utils/permissionController';
import { SubHeaderContext } from '../subHeader/subHeader-reducer';
import { useSnackbar } from '../Dialogs/SnackbarDialog/SnackbarService';

const UserGuideGettingStarted = ({ showUserGuidePopup, setShowUserGuidePopup }) => {
  const { language: lang, appState } = React.useContext(AppStateContext);
  const { freeTrailGuideData, stateUpdater, maps } = React.useContext(EDStateContext);
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const { count } = React.useContext(SubHeaderContext);

  const allContr = appState.get('allContr', 'contracts');
  const userRole = new UserRole(getRole('all', appState));
  const activeContractId = appState.get('allDb.active.contract', 'projects');
  const isProjectActive = appState.get('allDb.active.isActive', 'projects');

  const showUserGuidePop = (type: string) => {
    let userGuideData;
    switch (type) {
      case 'reporterTicketCreation':
        navigate({
          pathname: `/projects/${appState.get('active', 'projects')}/tickets`,
        });
        userGuideData = {
          ...freeTrailGuideData,
          isShowFlow: true,
          isGuideSkipped: false,
          type: 'reporterTicketCreation',
          isTicketFlowDone: false,
        };
        break;
      case "ticketChooseStep":
        navigate({
          pathname: `/projects/${appState.get('active', 'projects')}/tickets`,
        });
        userGuideData = {
          ...freeTrailGuideData,
          isShowFlow: true,
          isGuideSkipped: false,
          type: "ticketChooseStep",
          isTicketFlowDone: false,
        };
        break;
      case 'ticketSelect':
        navigate({
          pathname: `/projects/${appState.get('active', 'projects')}/tickets`,
        });
        userGuideData = {
          ...freeTrailGuideData,
          isShowFlow: true,
          isGuideSkipped: false,
          type: 'ticketSelect',
        };
        break;
      case 'libraryIntro':
        navigate({
          pathname: `/projects/${appState.get('active', 'projects')}/maps`,
        });

        userGuideData = {
          ...freeTrailGuideData,
          isShowFlow: true,
          isGuideSkipped: false,
          type: 'libraryIntro',
          isTicketFlowDone: false,
        };
        break;
      case 'audit':
        navigate({
          pathname: `/projects/${appState.get('active', 'projects')}/audits`,
        });
        userGuideData = {
          ...freeTrailGuideData,
          isShowFlow: true,
          isGuideSkipped: false,
          type: 'audit',
          isAuditFlowDone: false,
        };
        break;
      case 'auditGroup':
        navigate({
          pathname: `/projects/${appState.get('active', 'projects')}/audits`,
        });
        userGuideData = {
          ...freeTrailGuideData,
          isShowFlow: true,
          isGuideSkipped: false,
          type: 'auditGroup',
          isAuditFlowDone: false,
        };
        break;
      case 'templateGroup':
        navigate({
          pathname: `/projects/${appState.get('active', 'projects')}/templates`,
        });
        userGuideData = {
          ...freeTrailGuideData,
          isShowFlow: true,
          isGuideSkipped: false,
          type: 'templateGroup',
        };
        break;
      case 'reporter':
          if(maps.size > 0){
          navigate({
            pathname: `/projects/${appState.get('active', 'projects')}/reporter`,
          });
          userGuideData = {
            ...freeTrailGuideData,
            isShowFlow: true,
            isGuideSkipped: false,
            type: 'reporter',
            isReporterFlowDone: false,
          };
        } else {
          snackbar({ title: lang.m_lbl_reporter_guide_warn, hideCancel: true, open: true });
          return;
        }

        break;
      default:
        break;
    }
    setTimeout(() => {
      stateUpdater({ type: 'userGuideData', payload: userGuideData });
    }, 1000);
    setShowUserGuidePopup(false);
  };

  const roleBasedGuideOption = () =>{
    if(userRole?.accountable || userRole?.support){
      return showUserGuidePop("ticketChooseStep");
    }else if(userRole?.reporter){
      return showUserGuidePop("reporterTicketCreation");
    }
  }

  return (
    <>
      <div className="user-guide__main--header"></div>
      {showUserGuidePopup && (
        <div
          className="user-guide__main"
          onClick={() => {
            setShowUserGuidePopup(true);
          }}>
          {(userRole?.accountable || userRole?.support || userRole?.contractAdmin) && (
            <div className="user-guide__main--body">
              <ListSubheader className="listHeader">{lang.m_lbl_library}</ListSubheader>
              <MenuItem
                className="listItem"
                onClick={() => {
                  showUserGuidePop('libraryIntro');
                }}>
                {lang.m_lbl_upload_draw}
              </MenuItem>
            </div>
          )}
           {(userRole?.accountable || userRole?.support|| userRole?.rci || userRole?.reporter) && isProjectActive && <div className="user-guide__main--body">
              <ListSubheader className="listHeader">{lang.m_lbl_tkts}</ListSubheader>
              {(userRole?.accountable || userRole?.support || userRole?.reporter) && 
              <MenuItem className="listItem" onClick={() => roleBasedGuideOption()}>{lang.m_lbl_step_tckt_guide}</MenuItem>
              }
              {(count.ticket > 0) && 
              (userRole?.accountable || userRole?.support|| userRole?.rci || userRole?.reporter) && 
              <MenuItem className="listItem" onClick={() => showUserGuidePop("ticketSelect")}>{lang.m_lbl_check_ticket}</MenuItem>
              }
            </div>}
          {(allContr[activeContractId].module.audits === true) && isProjectActive && <div className="user-guide__main--body">
            {(userRole?.accountable || userRole?.support || userRole?.rci) && <ListSubheader className="listHeader">{lang.m_lbl_audts}</ListSubheader>}
            {(userRole?.accountable || userRole?.support) && (
              <MenuItem className="listItem" onClick={() => showUserGuidePop('audit')}>
                {lang.m_lbl_step_audit_guide}
              </MenuItem>
            )}
            {(userRole?.accountable || userRole?.support || userRole?.rci) && (
              <MenuItem className="listItem" onClick={() => showUserGuidePop('auditGroup')}>
                {lang.m_lbl_check_audits_guide}
              </MenuItem>
            )}
          </div>}
          {(userRole?.accountable || userRole?.support || userRole?.contractAdmin) && (allContr[activeContractId].module.audits === true) && (
            <div className="user-guide__main--body">
              <ListSubheader className="listHeader">{lang.m_lbl_templates_guide}</ListSubheader>
              <MenuItem className="listItem" onClick={() => showUserGuidePop('templateGroup')}>
                {lang.m_lbl_create_temp_guide}
              </MenuItem>
            </div>
          )}
          {(userRole?.accountable || userRole?.support || userRole?.contractAdmin) &&
            (allContr[activeContractId]?.pricePlan === 'EXPERT' || allContr[activeContractId]?.pricePlan === 'PRO') && (
              <div className="user-guide__main--body">
                <ListSubheader className="listHeader">{lang.m_lbl_reporter}</ListSubheader>
                <MenuItem className="listItem" onClick={() => showUserGuidePop('reporter')}>
                  {lang.m_lbl_step_reporter_guide}
                </MenuItem>
              </div>
            )}
        </div>
      )}
    </>
  );
};

export default React.memo(UserGuideGettingStarted);
