import { generateUUID } from '../utils/utils';

export interface ILicense {
  email: string;
  deviceId: string;
  ipAddress: string;
  apptype: string;
  appVersion: string;
  deviceType: string;
  database: string;
}
export class License implements ILicense {
  email: string;
  deviceId: string;
  ipAddress: string;
  apptype: string;
  appVersion: string;
  deviceType: string;
  database: string;
  constructor(appState) {
    this.email = appState.get('id', 'user') || '';
    this.deviceId = appState.get('deviceId', 'product') || generateUUID(true, appState);
    this.ipAddress = '';
    this.apptype = 'fullApp';
    this.appVersion = appState.get('version', 'product') || process.env.REACT_APP_VERSION || '';
    this.deviceType = 'web';
    this.database = '';
  }
}
