import React from 'react';

class Count {
  public ticket: number;
  public audit: number;
  public library: number;
  public template: number;
  public reporter: number;
  constructor(data: any = {}) {
    this.ticket = data.ticket || 0;
    this.audit = data.audit || 0;
    this.library = data.library || 0;
    this.template = data.template || 0;
    this.reporter = data.reporter || 0;
  }
}
export const SubHeaderContext = React.createContext<any>({});

function reducer(state, action) {
  switch (action.type) {
    case 'setCount':
      const countObj = new Count(action.count);

      if (action.module) {
        countObj[action.module] = action.value;
      }
      return { ...state, count: countObj };
    default:
      return state;
  }
}

const SubHeaderProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, { count: new Count({}) });

  const value = {
    count: state.count,
    setCount: (value: number, module: string) => {
      dispatch({ type: 'setCount', value, module, count: state.count });
    },
  };
  return <SubHeaderContext.Provider value={value}>{children}</SubHeaderContext.Provider>;
};

export default SubHeaderProvider;
