import { IActor, IAuthor } from './author';

export interface IOperation {
  changedProperties: string[];
  oldValues: string[];
  newValues: string[];
  author: string;
  time: string;
  summary: string;
  actionType: string;
  platform: IPlatform;
}

export interface IPlatform {
  userInterface: string;
  interfaceVersion: string;
}

export class Operation implements IOperation {
  public changedProperties: string[];
  public oldValues: string[];
  public newValues: string[];
  public author: string;
  public time: string;
  public summary: string;
  public actionType: string;
  public platform: IPlatform;
  constructor(data: any = {}) {
    this.changedProperties = data.changedProperties || [];
    this.oldValues = data.oldValues || [];
    this.newValues = data.newValues || [];
    this.author = data.author || '';
    this.time = data.time || new Date().toISOString();
    this.summary = data.summary || '';
    this.actionType = data.actionType || '';
    this.platform = data.platform || new Platform();
  }
}
export class Platform implements IPlatform {
  public userInterface: string;
  public interfaceVersion: string;
  constructor(data: any = {}) {
    this.userInterface = data.userInterface || 'web';
    this.interfaceVersion = data.interfaceVersion || process.env.REACT_APP_VERSION;
  }
}

/** Temp timline interface to be removed once deprecated */
export interface Changes {
  dueDate: string;
}
export interface Timeline {
  actor: IActor;
  time: string;
  operationType?: string;
  newValue?: string[] | string;
  operation?: string;
  type: string;
  person?: IAuthor;
  role?: string;
  changes?: Changes;
  start?: string;
  transition?: string;
  oldValue?: any[];
  rev?: string;
}
