export interface ILocation {
  latitude: number | null;
  location: string | null;
  longitude: number | null;
}

export class Location implements ILocation {
  latitude: number;
  location: string;
  longitude: number;
  constructor(data: any = {}) {
    this.latitude = data.latitude || null;
    this.location = data.location || '';
    this.longitude = data.location || null;
  }
}
